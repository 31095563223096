import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { useModal } from "context/ModalContext";
import { PiStarThin } from "react-icons/pi";
import { BiSolidLockAlt } from "react-icons/bi";

import { Button, Modal } from "flowbite-react";
import { AiOutlineExclamationCircle } from "react-icons/ai";

import "./styles.scss";

export function ModalSelecionarCardDesejo() {
  const { user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();

  // const [openModal2, setOpenModal2] = useState<string | undefined>();
  // const props = { openModal2, setOpenModal2 };

  // Função chamada quando um item é selecionado na modal
  const handleItemSelection = (selectedItemId: any) => {
    // Chame a função para atualizar o valor de initialSwap.rewards.item no componente pai
    // Você pode passar o ID do item selecionado como argumento
    modalData.addItemToRequires(selectedItemId);
  };

  // const createMySwap = () => {
  //   props.setOpenModal2(undefined);
  //   modalData.creteSwap();
  // };

  const modalID = "modal-selecionar-card-desejo";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  if (!user) return null;

  if (!modalData || modalData?.length < 1) return null;

  const cards = modalData.cards;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalSelecionarCardDesejo">
            <div className="bg-white flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>
              <h2>Selecione o card que você deseja receber em troca.</h2>
              <div className="text-center containerSwaps">
                {cards.map((item: any, i: number) => (
                  <div className="swap" key={i}>
                    <div className="infoHeader">
                      <div className="desafio">{i + 1}</div>
                      <p>{item.card.name}</p>
                    </div>
                    <div className="front">
                      <div className="cardFront">
                        <img src={item.imgPremio} className="imgBg" alt="" />
                        <img
                          src={item.card.image.medium.url}
                          className="imgDetailCard"
                          alt=""
                        />
                      </div>

                      <div className="presenteVerde">1</div>
                      <div className="infoObjective detailInfo">
                        <h2 className="titleObjectives"></h2>

                        <button
                          className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                          ng-click="selectCard(obj)"
                          onClick={() => {
                            handleItemSelection(item.card._id);
                          }}
                        >
                          QUERO ESTE CARD
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
