/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { UserInfo } from "components/user-info";
import { getTeamById } from "services/teams.service";
import {
  listMySwaps,
  getSwapPayment,
  deleteSwap,
  creteNewSwapChange,
  loadTotalStatusMercado,
  listGlobalSwaps,
  acquireGlobalSwap,
  loadTeamStatus,
} from "services/swaps.service";
import { getVirtualGoods } from "services/store.service";
import { UserAvatar } from "components/user-avatar";
import Tippy from "@tippyjs/react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import "./styles.scss";
import "./vitrine.scss";

import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import { toast } from "react-toastify";
import { RegulamentoFooter } from "components/regulamento-footer";
import {
  FaStore,
  FaQuestion,
  FaChevronLeft,
  FaTimesCircle,
} from "react-icons/fa";
import { TbCards } from "react-icons/tb";
import LiveCountdown from "./components/live-countdown";

import SwapIcon from "assets/img/swap_icon.png";
import { ModalSelecionarCard } from "./components/modal-selecionar-card";
import { Button, Modal, Label, TextInput } from "flowbite-react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { ModalSelecionarCardDesejo } from "./components/modal-selecionar-card-desejo";
import { ModalSwapCard } from "./components/modal-swap-card";
import LazyLoad from "react-lazy-load";
import { ModalEditCard1 } from "./components/modal-edit-card-1";
import { ModalEditCard2 } from "./components/modal-edit-card-2";

interface IFormInput {
  cpf: string;
}

export function MercadoDeTrocas() {
  const { refreshUser, user } = useAuth();
  const initialSwap = {
    name: "Swap",
    description: "Swap",
    seller: user?._id,
    requires: [
      {
        item: "",
        total: 1,
        type: 2,
        operation: 1,
      },
    ],
    rewards: [
      {
        item: "",
        total: 1,
        type: 2,
      },
    ],
    extra: {
      cardUnidade: false,
    },
  };
  const { showModal, closeModal } = useModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [tabactive, setTabActive] = useState<string>("mercado");
  const [teamOwner, setTeamOwner] = useState<any>();
  const [teamName, setTeamName] = useState<any>();
  const [cards, setCards] = useState<any>([]);
  const [mySwaps, setMySwaps] = useState<any>([]);
  const [openModal, setOpenModal] = useState<string | undefined>();
  const props = { openModal, setOpenModal };
  const [item, setItem] = useState<any>();
  const [itemUnidade, setItemUnidade] = useState<any>();

  const [createOfer, setCreateOfer] = useState<boolean>(false);
  const [stepOffer, setStepOffer] = useState<number>(0);
  const [stepVitrine, setStepVitrine] = useState<number>(0);
  const [stepVitrineUnidade, setStepVitrineUnidade] = useState<number>(0);

  const [newSwap, setNewSwap] = useState(initialSwap);
  const [totalStatusMercado, setTotalStatusMercado] = useState(0);
  const [totalStatusMercadoUnidade, setTotalStatusMercadoUnidade] = useState(0);
  const [vitrine, setVitrine] = useState<any>([]);
  const [swap1, setSwap1] = useState<any>();
  const [swap2, setSwap2] = useState<any>();
  const [swapConfirm, setSwapConfirm] = useState<any>();
  const [swapConfirmUnidade, setSwapConfirmUnidade] = useState<any>();
  const [swapOk, setSwapOk] = useState<boolean>(false);
  const [swapUnidadeOk, setSwapUnidadeOk] = useState<boolean>(false);
  const [teamStatus, setTeamStatus] = useState<any>();
  const [cardUnidade, setCardUnidade] = useState<boolean>(false);
  const [viewCards, setViewCards] = useState<string>("usuario");

  const { register, setValue, handleSubmit, reset } = useForm<IFormInput>();

  // Função para adicionar o item selecionado à propriedade rewards
  const addItemToRewards = (selectedItemId: any, cardUnidade?: any) => {
    const item1 = cards.filter((e: any) => {
      return e._id === selectedItemId;
    })[0];
    const premio = cards.find((e: any) =>
      e.requires.some((item: any) => item.item === selectedItemId)
    );
    item1.premio = premio;
    setSwap1(item1);
    setNewSwap({
      ...newSwap,
      rewards: [{ item: selectedItemId, total: 1, type: 2 }],
    });
    if (cardUnidade) {
      newSwap.extra.cardUnidade = true;
    }
    closeModal(); // Fechar a modal após adicionar o item
    if (stepOffer < 2) {
      setStepOffer(2);
    }
  };

  // Função para adicionar o item selecionado à propriedade requires
  const addItemToRequires = (selectedItemId: any) => {
    const item2 = cards.filter((e: any) => {
      return e._id === selectedItemId;
    })[0];
    const premio = cards.find((e: any) =>
      e.requires.some((item: any) => item.item === selectedItemId)
    );
    item2.premio = premio;
    setSwap2(item2);
    setNewSwap({
      ...newSwap,
      requires: [{ item: selectedItemId, total: 1, type: 2, operation: 1 }],
    });
    closeModal(); // Fechar a modal após adicionar o item
    if (stepOffer < 3) {
      setStepOffer(3);
    }
  };

  async function createMySwap() {
    if (!newSwap.requires[0].item) {
      toast.error(`❌ Selecione um card que deseja na troca.`, {
        theme: "colored",
      });
      return;
    }
    props.setOpenModal(undefined);
    const elemento = document.getElementById("main-body");
    elemento?.removeAttribute("style");
    changeStep(1);
    setCreateOfer(false);
    setStepOffer(0);

    if (newSwap.extra.cardUnidade === true) {
      newSwap.seller = user?.extra.unidade;
    } else {
      newSwap.seller = user?._id;
    }

    const data = await creteNewSwapChange(newSwap);
    let message = "";
    if (data.restrictions && data.restrictions[0]) {
      if (data.restrictions.indexOf("swap_is_required") !== -1) {
        message = "Card requerido para troca.";
      } else if (data.restrictions.indexOf("swap_id_not_allowed") !== -1) {
        message = "Troca não permitida.";
      } else if (data.restrictions.indexOf("player_does_not_exist") !== -1) {
        message = "Jogador inexistente.";
      } else if (data.restrictions.indexOf("requires_not_defined") !== -1) {
        message = "Os requisitos são obrigatórios em uma oferta de troca.";
      } else if (data.restrictions.indexOf("rewards_not_defined") !== -1) {
        message = "As recompensas são obrigatórias em uma oferta de troca.";
      } else if (
        data.restrictions.indexOf("rewards_and_requires_must_be_different") !==
        -1
      ) {
        message =
          "Não é permitido criar uma troca com requisitos iguais a recompensas.";
      } else if (data.restrictions.indexOf("insufficient_rewards") !== -1) {
        message =
          "Seu saldo de recompensas é insuficiente para criar esta troca.";
      } else if (
        data.restrictions.indexOf("nao_responsavel_pela_agencia") !== -1
      ) {
        message =
          "Você não pode criar uma troca em nome de uma agencia que você não é responsável.";
      }
      toast.error(`❌ ${message}.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (data.status === "OK") {
      refreshUser();
      setLoading(true);
      loadItemsCatalog();
      toast(`👍 sua troca foi criada com sucesso.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function showSwapConfirm(item: any) {
    setStepVitrine(1);
    setSwapConfirm(item);
  }

  function showSwapConfirmUnidade(item: any) {
    setStepVitrineUnidade(1);
    setSwapConfirmUnidade(item);
  }

  function getNameUnidade(nameUnidade: any) {
    let lastGreaterThanIndex = nameUnidade.lastIndexOf(")");

    if (lastGreaterThanIndex !== -1) {
      let result = nameUnidade.substr(lastGreaterThanIndex + 1).trim();
      return result;
    } else {
      console.log("Caractere '>' não encontrado na string.");
      return nameUnidade;
    }
  }

  async function loadItemsCatalog() {
    try {
      const data = await getVirtualGoods();
      setCards(data);
      loadMySwaps(data);
      loadCardsVitrine(data);
    } catch (error) {
      console.error("Erro ao buscar o catálogo de itens virtuais:", error);
    }
  }

  async function loadMySwaps(cards: any) {
    try {
      const data = await listMySwaps(user?._id, user?.extra.unidade);

      data.forEach(function (o: any) {
        cards.forEach(function (item: any) {
          let obj = { image: { medium: { url: "" } } };
          if (item._id === o.requires[0].item) {
            o.requires[0].card = item;
          }
          if (
            item.requires.some((req: any) => req.item === o.requires[0].item)
          ) {
            o.requires[0].premio = item;
          }
          if (item._id === o.rewards[0].item) {
            o.rewards[0].card = item;
          }
          if (
            item.requires.some((req: any) => req.item === o.rewards[0].item)
          ) {
            o.rewards[0].premio = item;
          }
        });
      });

      setMySwaps(data);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar minhas trocas:", error);
    }
  }

  async function loadTeam() {
    const data = await getTeamById(user?.extra.unidade);
    setTeamOwner(data.owner);

    let lastGreaterThanIndex = data.name.lastIndexOf(")");

    if (lastGreaterThanIndex !== -1) {
      let result = data.name.substr(lastGreaterThanIndex + 1).trim();
      setTeamName(result);
    } else {
      console.log("Caractere '>' não encontrado na string.");
      setTeamName(data.name);
    }

    if (user?._id === data.owner) {
      setCardUnidade(true);
    }
  }

  const handleFormSubmit = (isUnidade: boolean) => async (data: IFormInput) => {
    await onSubmitTrocaVitrine(data, isUnidade);
  };

  async function onSubmitTrocaVitrine(data: IFormInput, isUnidade?: boolean) {
    if (parseInt(data.cpf).toString() === user?._id) {
      props.setOpenModal(undefined);
      reset();
      if (user) {
        let idSendSwap = "";
        let swapObjSend = undefined;

        if (!isUnidade) {
          idSendSwap = user?._id;
          swapObjSend = swapConfirm;
        } else {
          idSendSwap = user?.extra.unidade;
          swapObjSend = swapConfirmUnidade;
        }

        const data = await acquireGlobalSwap(idSendSwap, swapObjSend);
        let message = "";
        if (data.restrictions && data.restrictions[0]) {
          if (data.restrictions.indexOf("swap_does_not_exist") !== -1) {
            message =
              "Ops! Esta oferta de troca não existe mais! Atualize sua página para ver novas ofertas.";
          } else if (
            data.restrictions.indexOf("swap_have_being_acquired") !== -1
          ) {
            message =
              "Ops! Parece que nosso mercado está agitado hoje. Alguém já comprou este item antes de você.";
          } else if (
            data.restrictions.indexOf("player_does_not_exist") !== -1
          ) {
            message = "Jogador inexistente";
          } else if (
            data.restrictions.indexOf("insufficient_requirements") !== -1
          ) {
            message = "Você não possui créditos para fazer esta troca.";
          }
          toast.error(`❌ ${message}.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          if (!isUnidade) {
            setSwapOk(true);
            refreshUser();
          } else {
            setSwapUnidadeOk(true);
          }

          setTimeout(function () {
            toast(`👍 sua troca foi realizada com sucesso.`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            const meuCardElements = document.querySelectorAll(".meuCard");
            const cardOferecidoElements =
              document.querySelectorAll(".cardOferecido");

            meuCardElements.forEach((element) => {
              element.classList.add("slide-right");
            });

            cardOferecidoElements.forEach((element) => {
              element.classList.add("slide-left");
            });
          }, 1000);
        }
      }
    } else {
      toast.error(`❌ o CPF digitado não é igual ao seu.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    const elemento = document.getElementById("main-body");
    elemento?.removeAttribute("style");
  }

  async function loadVitrine() {
    setSwapOk(false);
    const data1 = await loadTotalStatusMercado(user ? user._id : "");
    if (data1.length) {
      data1.forEach(function (o: any) {
        if (o._id === "cards") {
          setTotalStatusMercado(o.total);
        }
      });
    }
    loadCardsVitrine(cards);
  }

  async function loadVitrineUnidade() {
    setSwapUnidadeOk(false);
    const data1 = await loadTotalStatusMercado(user ? user.extra.unidade : "");
    if (data1.length) {
      data1.forEach(function (o: any) {
        if (o._id === "cards") {
          setTotalStatusMercadoUnidade(o.total);
        }
      });
    }
    loadCardsVitrine(cards, true);
  }

  async function loadCardsVitrine(cards: any, isListUnidade?: boolean) {
    try {
      let idLoad = "";
      if (isListUnidade) {
        idLoad = user?.extra.unidade;
      } else {
        idLoad = user ? user._id : "";
      }
      const data = await listGlobalSwaps(
        idLoad,
        user?.catalog_items,
        isListUnidade
      );

      data.forEach(function (o: any) {
        cards.forEach(function (item: any) {
          let obj = { image: { medium: { url: "" } } };
          if (item._id === o.requires[0].item) {
            o.requires[0].card = item;
          }
          if (
            item.requires.some((req: any) => req.item === o.requires[0].item)
          ) {
            o.requires[0].premio = item;
          }
          if (item._id === o.rewards[0].item) {
            o.rewards[0].card = item;
          }
          if (
            item.requires.some((req: any) => req.item === o.rewards[0].item)
          ) {
            o.rewards[0].premio = item;
          }
        });
      });

      setVitrine(data);
    } catch (error) {
      console.error("Erro ao buscar vitrine:", error);
    }
  }

  async function getTeamStatus() {
    let teamStatus: any[] = [];
    const data = await loadTeamStatus(user?.extra.unidade);
    if (data.length) {
      data.forEach(function (o: any) {
        teamStatus[o._id] = o.total;
      });
    }
    setTeamStatus(teamStatus);
  }

  useEffect(() => {
    if (user) {
      const completed = user.extra.avatar || user.image;
      if (!completed) {
        showModal("avatar-challenge");
      }
      setLoading(true);
      loadItemsCatalog();
      loadTeam();
      getTeamStatus();

      //loadVitrine();
    }
  }, [user]);

  const getDifferenceFromNow = (created: any) => {
    const createdDate = new Date(created).getTime();
    const currentDate = new Date().getTime();
    const differenceInMilliseconds = currentDate - createdDate;
    const differenceInSeconds = differenceInMilliseconds / 1000;

    const secondsInOneDay = 86400;
    const daysDifference = Math.floor(differenceInSeconds / secondsInOneDay);
    if (daysDifference >= 1) {
      return true;
    } else {
      return false;
    }
  };

  function createNewSwap() {
    setCreateOfer(true);
    setStepOffer(1);
  }

  async function receiveMySwapPayment(id: string) {
    try {
      const data = await getSwapPayment(id);
      if (data.status === "OK") {
        refreshUser();
        setLoading(true);
        loadItemsCatalog();
        toast(`👍 o card foi recebido com sucesso.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error(`❌ ocorreu um erro, tente novamente mais tarde.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  async function deleteMySwap(id: string) {
    try {
      const data = await deleteSwap(id, user ? user._id : "");
      if (data.status === "OK") {
        refreshUser();
        setLoading(true);
        loadItemsCatalog();
        props.setOpenModal(undefined);
        toast(`👍 a oferta de troca foi removida do mercado.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      props.setOpenModal(undefined);
      toast.error(`❌ ocorreu um erro, tente novamente mais tarde.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  function cancelNewSwap() {
    setCreateOfer(false);
    setItemUnidade("");
    changeStep(0);
    setViewCards("usuario");
  }

  function changeStep(step: number) {
    setStepOffer(step);
  }

  function isTeamManager() {
    return user?._id === teamOwner;
  }

  function totRequirement(item: any) {
    if (item.cardUnidade) {
      delete item.cardUnidade;
    }
    let ok = 0;
    if (item && item.requires) {
      item.requires.forEach(function (o: any) {
        if (o.type === 0 && user?.point_categories[o.item]) {
          ok = ok + user?.point_categories[o.item];
        } else if (o.type === 1 && user?.challenges[o.item]) {
          ok = ok + user?.challenges[o.item];
        } else if (o.type === 2 && user?.catalog_items[o.item]) {
          ok = ok + user?.catalog_items[o.item];
        }
      });
    }
    return ok;
  }

  function totTeamRequirement(item: any) {
    let ok = 0;
    if (isTeamManager()) {
      if (item && item.requires) {
        item.requires.forEach(function (o: any) {
          if (o.type === 0 && teamStatus[o.item]) {
            ok = ok + teamStatus[o.item];
          } else if (o.type === 1 && teamStatus[o.item]) {
            ok = ok + teamStatus[o.item];
          } else if (o.type === 2 && teamStatus[o.item]) {
            ok = ok + teamStatus[o.item];
          }
        });
      }
      item.cardUnidade = true;
      return ok;
    } else {
      return ok;
    }
  }

  function totRequirementOk(item: any) {
    let ok = 0;
    if (item && item.requires) {
      item.requires.forEach(function (o: any) {
        if (
          o.type === 0 &&
          user?.point_categories[o.item] &&
          user?.point_categories[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (
          o.type === 1 &&
          user?.challenges[o.item] &&
          user?.challenges[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (
          o.type === 2 &&
          user?.catalog_items[o.item] &&
          user?.catalog_items[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (o.type >= 3) {
          //ignorar qualquer outro tipo como level, crowning etc.
          ok = ok + 1;
        }
      });
    }
    return ok;
  }

  function totTeamRequirementOk(item: any) {
    let ok = 0;
    if (isTeamManager()) {
      if (item && item.requires) {
        item.requires.forEach(function (o: any) {
          if (
            o.type === 0 &&
            teamStatus[o.item] &&
            teamStatus[o.item] >= o?.total
          ) {
            ok = ok + 1;
          } else if (
            o.type === 1 &&
            teamStatus[o.item] &&
            teamStatus[o.item] >= o?.total
          ) {
            ok = ok + 1;
          } else if (
            o.type === 2 &&
            teamStatus[o.item] &&
            teamStatus[o.item] >= o?.total
          ) {
            ok = ok + 1;
          } else if (o.type >= 3) {
            //ignorar qualquer outro tipo como level, crowning etc.
            ok = ok + 1;
          }
        });
      }
      return ok;
    } else {
      return ok;
    }
  }

  function isRequirementUnlocked(o: any) {
    let ok = false;
    if (
      o.type === 0 &&
      user?.point_categories[o.item] &&
      user?.point_categories[o.item] >= o?.total
    ) {
      ok = true;
    } else if (
      o.type === 1 &&
      user?.challenges[o.item] &&
      user?.challenges[o.item] >= o?.total
    ) {
      ok = true;
    } else if (
      o.type === 2 &&
      user?.catalog_items[o.item] &&
      user?.catalog_items[o.item] >= o?.total
    ) {
      ok = true;
    } else if (o.type >= 3) {
      //ignorar qualquer outro tipo como level, crowning etc.
      ok = true;
    }
    return ok;
  }

  function isTeamRequirementUnlocked(o: any) {
    let ok = false;
    if (isTeamManager()) {
      if (
        o.type === 0 &&
        teamStatus[o.item] &&
        teamStatus[o.item] >= o?.total
      ) {
        ok = true;
      } else if (
        o.type === 1 &&
        teamStatus[o.item] &&
        teamStatus[o.item] >= o?.total
      ) {
        ok = true;
      } else if (
        o.type === 2 &&
        teamStatus[o.item] &&
        teamStatus[o.item] >= o?.total
      ) {
        ok = true;
      } else if (o.type >= 3) {
        //ignorar qualquer outro tipo como level, crowning etc.
        ok = true;
      }
      return ok;
    } else {
      return ok;
    }
  }

  function getItem(id: string) {
    let obj = { image: { medium: { url: "" } }, catalogId: "" };
    cards?.forEach(function (o: any) {
      if (o._id === id) {
        obj = o;
      }
    });
    return obj;
  }

  function showModalCards(item: any) {
    let cards: any[] = [];

    if (item && item.requires) {
      item.requires.forEach(function (o: any) {
        let card = {
          imgPremio: "",
          my: null,
          card: { image: { medium: { url: "" } } },
          type: "",
          cardUnidade: false,
        };
        card.imgPremio = item.image.medium.url;
        card.card = getItem(o.item);
        card.my = item.cardUnidade
          ? teamStatus[o.item]
          : user?.catalog_items[o.item];
        if (item.cardUnidade) {
          card.cardUnidade = true;
        }
        cards.push(card);
      });
    }
    showModal("modal-selecionar-card", { cards, addItemToRewards });
  }

  function showModalCardsDesejo(item: any) {
    let cards: any[] = [];

    if (item && item.requires) {
      item.requires.forEach(function (o: any) {
        let card = {
          imgPremio: "",
          my: null,
          card: { image: { medium: { url: "" } } },
          type: "",
        };
        card.imgPremio = item.image.medium.url;
        card.card = getItem(o.item);
        cards.push(card);
      });
    }
    showModal("modal-selecionar-card-desejo", { cards, addItemToRequires });
  }

  function updateTime(date: any) {
    let hours = 24 - date.getHours();
    let min = 60 - date.getMinutes();
    let sec = 60 - date.getSeconds();

    date.setHours(hours);
    date.setMinutes(min);
    date.setSeconds(sec);
    return date;
  }

  function turnVitrineClickHandler(event: any) {
    const bgSwapList = event.target.closest(".bgSwapList");
    if (bgSwapList) {
      bgSwapList.classList.remove("flip-vertical-bck");
      bgSwapList.classList.add("flip-vertical-fwd");
    }

    const frente = event.target.closest(".frente");
    if (frente) {
      frente.style.display = "none";
      const costas = frente.nextElementSibling;
      if (costas && costas.classList.contains("costas")) {
        costas.style.display = "block";
      }
    }
  }

  function rotateIconBackClickHandler(event: any) {
    const bgSwapList = event.target.closest(".bgSwapList");
    if (bgSwapList) {
      bgSwapList.classList.remove("flip-vertical-fwd");
      bgSwapList.classList.add("flip-vertical-bck");

      const frente = bgSwapList.querySelector(".frente");
      if (frente) {
        frente.style.display = "block";
        frente.style.transform = "rotateY(180deg)";
      }
    }

    const costas = event.target.closest(".costas");
    if (costas) {
      costas.style.display = "none";

      const bgObjetivos = costas.closest(".bgObjetivos");
      if (bgObjetivos) {
        const frente = bgObjetivos.querySelector(".frente");
        if (frente) {
          frente.style.removeProperty("style");
        }
      }
    }
  }

  function showModalEdit1() {
    showModal("modal-edit-card-1", {
      cards,
      totRequirement,
      isRequirementUnlocked,
      totRequirementOk,
      totTeamRequirement,
      isTeamRequirementUnlocked,
      totTeamRequirementOk,
      showModalCards,
      getItem,
      itemUnidade,
    });
  }
  function showModalEdit2() {
    showModal("modal-edit-card-2", { cards, showModalCardsDesejo });
  }

  function listNovasOfertasVitrine(isUnidade?: boolean) {
    if (!isUnidade) {
      setSwapOk(false);
      refreshUser();
      setStepVitrine(0);
      if (user) {
        listGlobalSwaps(user?._id, cards);
      }
    } else {
      setSwapUnidadeOk(false);
      setStepVitrineUnidade(0);
      if (user) {
        listGlobalSwaps(user?.extra.unidade, cards, isUnidade);
      }
    }
  }

  function informeCardsUnidade() {
    props.setOpenModal(undefined);
    showModalCards(itemUnidade);
  }

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div
          className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:p-10"
          id="pageTrocas"
        >
          <NavMenu />

          <div className="w-full flex justify-center p-2 md:p-0">
            <UserInfo />
          </div>
          <div className="bg-[#0938A3] w-screen text-white md:-mx-10 bgShadow">
            <div className="w-full md:w-[1200px] mx-auto  p-5 md:p-8">
              <h2>
                Troque seus <strong>cards</strong>
              </h2>

              <div className="flex w-full md:w-[1000px] tabs">
                <button
                  onClick={() => setTabActive("mercado")}
                  className={`${tabactive === "mercado" ? "active" : ""}`}
                >
                  {" "}
                  <p>
                    <FaStore /> MEU MERCADO <br />{" "}
                  </p>
                  {stepOffer >= 1 && (
                    <button
                      className="bg-[#cb0e0e] text-[#FFFFFF] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10 cancelSwapButton"
                      onClick={cancelNewSwap}
                      title="Cancelar troca mercado"
                    >
                      <small className="cancelarTroca">
                        <FaTimesCircle className="text-[#FFFFFF] mx-auto text-[1.6em] mr-auto" />
                        Cancelar
                      </small>
                    </button>
                  )}
                  <small>Crie oferta de cards.</small>
                  <Tippy
                    arrow={false}
                    className="hidden lg:block bg-blue-light drop-shadow-lg p-4"
                    allowHTML={true}
                    touch={"hold"}
                    content={
                      "Você pode criar até seis ofertas em um período de 24h, após este período você pode optar por retirar a sua oferta da troca"
                    }
                  >
                    <span className="question">?</span>
                  </Tippy>
                </button>
                <button
                  onClick={() => {
                    setTabActive("vitrine");
                    loadVitrine();
                  }}
                  className={`${tabactive === "vitrine" ? "active" : ""}`}
                >
                  {" "}
                  <p>
                    <TbCards /> VITRINE <br />{" "}
                  </p>
                  <small>Encontre os cards que você deseja.</small>
                  <Tippy
                    arrow={false}
                    className="hidden lg:block bg-blue-light drop-shadow-lg p-4"
                    allowHTML={true}
                    touch={"hold"}
                    content={
                      "Aqui são mostrados os cards disponíveis para troca de acordo com os cards que você tem disponíveis para trocar."
                    }
                  >
                    <span className="question">?</span>
                  </Tippy>
                </button>

                {cardUnidade && (
                  <button
                    onClick={() => {
                      setTabActive("vitrineUnidade");
                      loadVitrineUnidade();
                    }}
                    className={`${
                      tabactive === "vitrineUnidade" ? "active" : ""
                    }`}
                  >
                    {" "}
                    <p>
                      <TbCards /> VITRINE UNIDADE
                      <br />{" "}
                    </p>
                    <small>Encontre os cards que unidade deseja.</small>
                    <Tippy
                      arrow={false}
                      className="hidden lg:block bg-blue-light drop-shadow-lg p-4"
                      allowHTML={true}
                      touch={"hold"}
                      content={
                        "Aqui são mostrados os cards que unidade pode trocar."
                      }
                    >
                      <span className="question">?</span>
                    </Tippy>
                  </button>
                )}
              </div>
              {tabactive === "mercado" && !createOfer && (
                <div className="contentTabs">
                  {!loading && (
                    <div className="text-center">
                      {mySwaps.length === 0 && (
                        <h3 className="text-[#767474] text-[1.4em] mt-10">
                          Sem trocas ativas no mercado.
                        </h3>
                      )}
                    </div>
                  )}
                  {!loading && (
                    <div className="containerSwaps">
                      {mySwaps.length > 0 &&
                        mySwaps.map((item: any) => (
                          <div className="swap" key={item._id}>
                            {!item.buyer ? (
                              <div>
                                <div className="infoHeader">
                                  <div className="imgUser">
                                    {!!user.extra.avatar ? (
                                      <div className="avatarContent">
                                        <UserAvatar
                                          data={{ ...user, ...user?.extra }}
                                          responsive={true}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        src={user.image.small.url}
                                        alt="imgPlayer"
                                        className="imgList"
                                      />
                                    )}
                                  </div>
                                  <p>
                                    {user.name} <br />{" "}
                                    <span>Card que ofertei</span>
                                  </p>
                                </div>
                                <div className="front">
                                  <LazyLoad height={150} offset={150}>
                                    <div className="cardFront">
                                      <img
                                        className="imgBg"
                                        alt=""
                                        src={
                                          item.rewards[0].premio.image.medium
                                            .url
                                        }
                                      />
                                      <img
                                        src={
                                          item.rewards[0].card.image.medium.url
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </LazyLoad>
                                  <div className="swapTimer">
                                    <img src="/assets/swap_icon.png" alt="" />
                                    {!getDifferenceFromNow(item.created) ? (
                                      <LiveCountdown
                                        data={new Date(item.created)}
                                      />
                                    ) : (
                                      <>
                                        <button
                                          className="bg-[#cb0e0e] p-1"
                                          onClick={() => {
                                            props.setOpenModal("pop-up");
                                            setItem(item);
                                          }}
                                        >
                                          RETIRAR OFERTA
                                        </button>
                                      </>
                                    )}
                                  </div>
                                  <LazyLoad height={200} offset={200}>
                                    <div className="infoObjective infoObjective2">
                                      <img
                                        src={
                                          item.requires[0].premio.image.medium
                                            .url
                                        }
                                        alt=""
                                        className="imgBg"
                                      />
                                      <img
                                        src={
                                          item.requires[0].card.image.medium.url
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </LazyLoad>
                                  <div className="cardDesejoGreen">
                                    CARD DESEJADO
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="infoHeader">
                                  <div className="imgUser">
                                    {!!user.extra.avatar ? (
                                      <div className="avatarContent">
                                        <UserAvatar
                                          data={{ ...user, ...user?.extra }}
                                          responsive={true}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        src={user.image.small.url}
                                        alt="imgPlayer"
                                        className="imgList"
                                      />
                                    )}
                                  </div>
                                  <p>{user.name}</p>
                                </div>
                                <div className="front">
                                  <div className="cardFront">
                                    <img
                                      src={
                                        item.requires[0].premio.image.medium.url
                                      }
                                      alt=""
                                      className="imgBg"
                                    />
                                    <img
                                      src={
                                        item.requires[0].card.image.medium.url
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="infoObjective infoObjective2">
                                    <h3 className="text-[1.8em] text-[#072e92] font-bold">
                                      Ótima escolha
                                    </h3>{" "}
                                    <br />
                                    {item.extra.cardUnidade ? (
                                      <p>
                                        A troca com{" "}
                                        <strong>
                                          {getNameUnidade(item.name)}
                                        </strong>{" "}
                                        foi realizada com sucesso. <br />{" "}
                                        Aproveitem o novo card.
                                      </p>
                                    ) : (
                                      <p>
                                        Sua troca com{" "}
                                        <strong>{item.name}</strong> foi
                                        realizada com sucesso. <br /> Aproveite
                                        seu novo card.
                                      </p>
                                    )}
                                  </div>
                                  <button
                                    className="bg-[#062784] p-2"
                                    onClick={() =>
                                      receiveMySwapPayment(item._id)
                                    }
                                  >
                                    QUERO RESGATAR
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      <Modal
                        show={props.openModal === "pop-up"}
                        size="md"
                        popup
                        onClose={() => props.setOpenModal(undefined)}
                      >
                        <Modal.Header />
                        <Modal.Body>
                          <div className="text-center">
                            <AiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                              Retirar a oferta do card?
                            </h3>
                            <div className="flex justify-center gap-4">
                              <Button
                                color="gray"
                                onClick={() => props.setOpenModal(undefined)}
                              >
                                Cancelar
                              </Button>
                              <Button onClick={() => deleteMySwap(item._id)}>
                                Confirmar
                              </Button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  )}
                  {loading && (
                    <h3 className="text-center text-gray-900 text-[1.5em]">
                      CARREGANDO...
                    </h3>
                  )}
                  {!loading && mySwaps.length < 6 && !createOfer && (
                    <div className="flex justify-center items-center">
                      <button
                        className="bg-[#88e1f2] text-[#072e92] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10"
                        onClick={createNewSwap}
                      >
                        <b>Criar nova oferta</b>
                      </button>
                    </div>
                  )}
                </div>
              )}
              {tabactive === "mercado" && createOfer && (
                <div className="contentTabs">
                  {stepOffer < 3 && (
                    <>
                      <button
                        className={`ml-2 text-[1.2em] stepOne p-2 text-gray-900 ${
                          stepOffer === 1 ? "stepActive" : ""
                        }`}
                        //onClick={() => changeStep(1)}
                      >
                        <span>1</span> Selecione um card para criar uma oferta.
                      </button>
                      <button
                        className={`text-[1.2em] stepTwo p-2 text-gray-900 ${
                          stepOffer === 2 ? "stepActive" : ""
                        }`}
                        //onClick={() => changeStep(2)}
                      >
                        <span>2</span> Selecione o card que deseja em troca.
                      </button>
                    </>
                  )}
                  {stepOffer === 1 && (
                    <>
                      {cardUnidade && (
                        <div className="buttons flex justify-center items-center mb-6">
                          <button
                            className={`bg-[#C4C4C4] text-white p-2 w-[150px] ${
                              viewCards === "usuario" ? "active" : ""
                            }`}
                            onClick={() => setViewCards("usuario")}
                          >
                            MEUS CARDS
                          </button>
                          <button
                            className={`bg-[#C4C4C4] text-white p-2 w-[150px] whitespace-nowrap ml-5 ${
                              viewCards === "unidade" ? "active" : ""
                            }`}
                            onClick={() => setViewCards("unidade")}
                          >
                            CARDS DA UNIDADE
                          </button>
                        </div>
                      )}
                      <div className="">
                        {viewCards === "usuario" && (
                          <div className="containerSwaps">
                            {cards
                              ?.filter((e: any) => {
                                if (
                                  e.catalogId === "premios" &&
                                  totRequirement(e) >= 1 &&
                                  e.requires.length > 1
                                ) {
                                  return e;
                                }
                                return false;
                              })
                              .map((item: any) => (
                                <div className="swap" key={item._id}>
                                  <div className="front">
                                    <div className="infoHeader">
                                      <div className="desafio">1</div>
                                      <p>{item.name}</p>
                                    </div>
                                    <div className="cardFront">
                                      <img
                                        src={item.image.medium.url}
                                        className="imgBg"
                                        alt=""
                                      />
                                      {item.requires.map(
                                        (r: any, i: number) => (
                                          <div key={i}>
                                            <span>
                                              {isRequirementUnlocked(r) && (
                                                <img
                                                  src={
                                                    getItem(r.item).image.medium
                                                      .url
                                                  }
                                                  alt=""
                                                />
                                              )}
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div className="verMais">
                                      <div className="progressCard">
                                        <span>
                                          {totRequirementOk(item)}
                                          <span>/{item.requires.length}</span>
                                        </span>
                                      </div>
                                      <div className="">
                                        <TbCards />
                                      </div>
                                      <div className="text-[1.3em] text-[#062784]">
                                        <p>Eu tenho</p>

                                        <p>
                                          <strong>
                                            {totRequirement(item)}
                                          </strong>{" "}
                                          card(s)
                                        </p>
                                      </div>
                                      <div className="">
                                        <button
                                          className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                                          onClick={() => showModalCards(item)}
                                        >
                                          VER CARDS
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}

                        {viewCards === "unidade" && (
                          <div className="containerSwaps">
                            {cards
                              ?.filter((e: any) => {
                                if (
                                  e.catalogId === "premios" &&
                                  totTeamRequirement(e) >= 1 &&
                                  e.requires.length > 1
                                ) {
                                  return e;
                                }
                                return false;
                              })
                              .map((item: any) => (
                                <div className="swap" key={item._id}>
                                  <div className="front">
                                    <div className="infoHeader">
                                      <div className="desafio">1</div>
                                      <p>{item.name}</p>
                                    </div>
                                    <div className="cardFront">
                                      <img
                                        src={item.image.medium.url}
                                        className="imgBg"
                                        alt=""
                                      />
                                      {item.requires.map(
                                        (r: any, i: number) => (
                                          <div key={i}>
                                            <span>
                                              {isTeamRequirementUnlocked(r) && (
                                                <img
                                                  src={
                                                    getItem(r.item).image.medium
                                                      .url
                                                  }
                                                  alt=""
                                                />
                                              )}
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div className="verMais">
                                      <div className="progressCard">
                                        {totTeamRequirementOk(item)}
                                        <span>/{item.requires.length}</span>
                                      </div>
                                      <div className="">
                                        <TbCards />
                                      </div>
                                      <div className="text-[1.3em] text-[#062784]">
                                        <p>Unidade tem</p>
                                        <p>
                                          <strong>
                                            {totTeamRequirement(item)}
                                          </strong>{" "}
                                          card(s)
                                        </p>
                                      </div>
                                      <div className="">
                                        <button
                                          className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                                          onClick={() => {
                                            props.setOpenModal(
                                              "informe-cards-unidade"
                                            );
                                            setItemUnidade(item);
                                          }}
                                        >
                                          VER CARDS
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}

                        <Modal
                          show={props.openModal === "informe-cards-unidade"}
                          size="md"
                          popup
                          onClose={() => props.setOpenModal(undefined)}
                        >
                          <Modal.Header />
                          <Modal.Body>
                            <div className="text-center">
                              <AiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-[#c7c702]" />
                              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                Este card é da sua unidade, deseja proseguir?
                              </h3>
                              <div className="flex justify-center gap-4">
                                <Button
                                  color="gray"
                                  onClick={() => props.setOpenModal(undefined)}
                                >
                                  Cancelar
                                </Button>
                                <Button onClick={() => informeCardsUnidade()}>
                                  Confirmar
                                </Button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </>
                  )}
                  {stepOffer === 2 && (
                    <div className="containerSwaps premios">
                      {cards
                        ?.filter((e: any) => {
                          if (
                            e.catalogId === "premios" &&
                            e.requires.length > 1
                          ) {
                            return e;
                          }
                          return false;
                        })
                        .map((item: any) => (
                          <div className="swap" key={item._id}>
                            <div className="front">
                              <div className="infoHeader">
                                <div className="desafio">1</div>
                                <p>{item.name}</p>
                              </div>
                              <div className="cardFront">
                                <img
                                  src={item.image.medium.url}
                                  className="cardsIWant"
                                  alt=""
                                />

                                {/* {item.requires.map((r: any, i: number) => (
                                  <div key={i}>
                                    <img
                                      ng-if="r.item != newSwap.rewards[0].item"
                                      src={getItem(r.item).image.medium.url}
                                      alt=""
                                    />
                                  </div>
                                ))} */}
                              </div>
                              <div className="">
                                <p>
                                  <br />
                                </p>
                                <button
                                  className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                                  onClick={() => showModalCardsDesejo(item)}
                                >
                                  VER CARDS
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {stepOffer === 3 && (
                    <div className="containerSwaps">
                      <div className="swapArea flex w-full">
                        <div className="swap">
                          <div className="titleChange first">
                            <img src="img/checkGrey.png" alt="" />
                            {!itemUnidade ? (
                              <span>Card que você quer ofertar.</span>
                            ) : (
                              <span>Card que a unidade vai ofertar.</span>
                            )}
                          </div>
                          <div className="col-xs-12 col-sm-10 col-sm-offset-2">
                            <div className="changeCreate">
                              <div className="front">
                                <div className="cardFront">
                                  <img
                                    src={swap1.premio.image.medium.url}
                                    className="imgBg"
                                    alt=""
                                  />
                                  <img src={swap1.image.medium.url} alt="" />
                                </div>
                                <div className="infoObjective infoObjective2">
                                  <h2 className="titleObjectives text-[#0938A3]">
                                    Bom Negócio!
                                  </h2>
                                  {!itemUnidade ? (
                                    <p>
                                      Ofertando este Card, você aumenta as{" "}
                                      <br />
                                      chances de conseguir o Card que deseja!.
                                    </p>
                                  ) : (
                                    <p>
                                      Ofertando este card, você aumenta as
                                      chances da sua <b>Unidade</b> conseguir o
                                      card que precisa.
                                    </p>
                                  )}
                                  <button
                                    onClick={showModalEdit1}
                                    className="mt-5"
                                  >
                                    <img
                                      src="/assets/editarOferta.png"
                                      alt=""
                                      className="like"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <button
                            className="createOffer bg-[#4bb313] text-[#FFFFF] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10 ml-5"
                            onClick={() => props.setOpenModal("dismissible")}
                          >
                            CRIAR OFERTA!
                          </button>
                        </div>
                        <div className="swap">
                          <div className="titleChange second">
                            <img src="img/checkGrey.png" alt="" />
                            <span>Card que deseja em troca.</span>
                          </div>
                          <div className="col-xs-12 col-sm-10">
                            <div className="changeCreate">
                              <div className="front">
                                <div className="cardFront">
                                  <img
                                    src={swap2.premio.image.medium.url}
                                    className="imgBg"
                                    alt=""
                                  />
                                  <img src={swap2.image.medium.url} alt="" />
                                </div>
                                <div className="infoObjective infoObjective2">
                                  <h2 className="titleObjectives text-[#0938A3]">
                                    Ótima Escolha!
                                  </h2>
                                  {!itemUnidade ? (
                                    <p>
                                      Você pode estar muito próximo do <br />
                                      Card que deseja! agora é só torcer.
                                    </p>
                                  ) : (
                                    <p>
                                      Sua <b>Unidade</b> está muito próximo do
                                      card que deseja! Agora é só torcer.
                                    </p>
                                  )}
                                  <button
                                    onClick={showModalEdit2}
                                    className="mt-5"
                                  >
                                    <img
                                      src="/assets/editarOferta.png"
                                      alt=""
                                      className="like"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-center items-center">
                    {/* {stepOffer === 1 && (
                      <button
                        className="bg-[#88e1f2] text-[#072e92] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10 ml-5 hover:text-[#072e92]"
                        onClick={() => setStepOffer(2)}
                      >
                        <b>Próximo</b>
                      </button>
                    )} */}
                    {stepOffer === 2 && (
                      <>
                        <button
                          className="bg-[#88e1f2] text-[#072e92] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10 ml-5 hover:text-[#072e92]"
                          onClick={() => setStepOffer(1)}
                        >
                          <b>Aterior</b>
                        </button>
                        {/* <button
                          className="bg-[#4bb313] text-[#FFFFF] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10 ml-5"
                          onClick={() => props.setOpenModal("dismissible")}
                        >
                          <b>Concluir</b>
                        </button> */}
                      </>
                    )}
                  </div>
                  <Modal
                    dismissible
                    show={props.openModal === "dismissible"}
                    size="md"
                    popup
                    onClose={() => props.setOpenModal(undefined)}
                  >
                    <Modal.Header />
                    <Modal.Body>
                      <div className="text-center">
                        <AiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Concluir a criação de troca de card?
                        </h3>
                        <div className="flex justify-center gap-4">
                          <Button
                            color="gray"
                            onClick={() => props.setOpenModal(undefined)}
                          >
                            Cancelar
                          </Button>
                          <Button onClick={() => createMySwap()}>
                            Confirmar
                          </Button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              )}
              {tabactive === "vitrine" && (
                <div className="contentTabs">
                  <div className="targetAbas" id="vitrine">
                    <div className="flex justify-center items-center mb-5">
                      <div className="meuscardsdrop">
                        <img src="img/meus-cards.png" alt="" />
                        <Link to="/premios" className="text-[#c4c4c4]">
                          TENHO
                          <strong> {totalStatusMercado} CARDS</strong>
                          <i className="glyphicon glyphicon-chevron-down"></i>
                        </Link>
                      </div>
                      <div className="countDown text-[#c4c4c4] ml-10">
                        <div className="countDownNewCards flex">
                          NOVOS CARDS EM{" "}
                          <strong className="ml-2">
                            <LiveCountdown
                              data={new Date(updateTime(new Date()))}
                            />
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerSwaps">
                    {stepVitrine === 0 &&
                      vitrine &&
                      vitrine.length > 0 &&
                      vitrine.map((item: any) => (
                        <div
                          className="col-xs-12 col-sm-4 listVitrine swap bgSwapList"
                          key={item._id}
                        >
                          <div className="text-center ">
                            <div className="frente">
                              <div className="infoHeader">
                                <div className="imgUser">
                                  <img src="/assets/img-profile.png" alt="" />
                                </div>
                                <p className="text-left">
                                  Conheca após a troca <br />{" "}
                                  <span>Card Ofertado</span>
                                </p>
                              </div>
                              <div className="cardFront">
                                <img
                                  src={item.rewards[0].premio.image.medium.url}
                                  className="imgBg"
                                  alt=""
                                />
                                <img
                                  src={item.rewards[0].card.image.medium.url}
                                  alt=""
                                />
                                <img
                                  src="/assets/rotateIcon.png"
                                  alt=""
                                  className="rotateIcon"
                                  onClick={(event) =>
                                    turnVitrineClickHandler(event)
                                  }
                                  title="girar"
                                />
                              </div>
                              <h3>
                                PARTE <br /> {item.rewards[0].card.name}
                              </h3>
                              <p>
                                <strong>Aproveite a oferta</strong> e garanta a{" "}
                                <strong>
                                  parte {item.rewards[0].card.name}
                                </strong>
                                <br /> de um(a){" "}
                                <strong>
                                  {item.rewards[0].card.description}
                                </strong>
                              </p>
                              <br />
                              <br />
                              <span>
                                Você possui o card necessário para negociar.
                              </span>
                              <br />
                              <br />
                              <button
                                className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                                onClick={(event) =>
                                  turnVitrineClickHandler(event)
                                }
                              >
                                GIRE E DESCUBRA
                              </button>
                            </div>
                            <div className="costas">
                              <img
                                src="img/topo_card_off.png"
                                alt=""
                                className="cardOff"
                              />
                              <div className="infoHeader">
                                <div className="imgUser">
                                  <img src="/assets/img-profile.png" alt="" />
                                </div>
                                <p className="text-left">
                                  Conheca após a troca <br />{" "}
                                  <span>Card Desejado</span>
                                </p>
                              </div>
                              <div className="cardFront">
                                <img
                                  src={item.requires[0].premio.image.medium.url}
                                  className="imgBg"
                                  alt=""
                                />
                                <img
                                  src={item.requires[0].card.image.medium.url}
                                  alt=""
                                />
                                <img
                                  src="/assets/rotateIcon.png"
                                  alt=""
                                  className="rotateIcon back"
                                  onClick={(event) =>
                                    rotateIconBackClickHandler(event)
                                  }
                                  title="girar"
                                />
                              </div>
                              <h3>PARTE {item.rewards[0].card.name}</h3>
                              <p>
                                Em troca desejo a{" "}
                                <strong>
                                  parte {item.requires[0].card.name}
                                </strong>
                                <br /> de um(a){" "}
                                <strong>
                                  {item.requires[0].card.description}
                                </strong>
                              </p>
                              <br />
                              <span>
                                <strong>Parece uma boa oferta.</strong> Você tem{" "}
                                <strong>
                                  {user.catalog_items[item.requires[0].item]}{" "}
                                  card(s)
                                </strong>{" "}
                                dessa parte. Deseja trocar?
                              </span>
                              <br />
                              <button
                                ng-click="showSwap(obj)"
                                onClick={() => showSwapConfirm(item)}
                                className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                              >
                                QUERO TROCAR
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}

                    {stepVitrine === 1 && (
                      <div className="confirmSwap">
                        {!swapOk && (
                          <>
                            <div className="text-left">
                              <button
                                className="ml-6 p-2 px-8 text-[#FFF] bg-[#062784] flex items-center"
                                onClick={() => setStepVitrine(0)}
                              >
                                <FaChevronLeft className="mr-2" />
                                VOLTAR
                              </button>
                            </div>
                            <div className="text-center">
                              <h2>Você está muito perto da peça que deseja!</h2>
                            </div>
                          </>
                        )}
                        {swapOk && (
                          <div className="text-center">
                            <h2>Parabéns, você conseguiu!</h2>
                          </div>
                        )}
                        <div className="flex flex-wrap">
                          <div className="swap meuCard">
                            <div className="">
                              <div className="">
                                <div className="infoHeader">
                                  <div className="desafio">1</div>
                                  <p>
                                    PARTE ({swapConfirm.requires[0].card.name})
                                  </p>
                                </div>
                                <div className="front">
                                  <div className="cardFront">
                                    <img
                                      src={
                                        swapConfirm.requires[0].premio.image
                                          .medium.url
                                      }
                                      className="imgBg"
                                      alt=""
                                    />
                                    <img
                                      src={
                                        swapConfirm.requires[0].card.image
                                          .medium.url
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="infoObjective">
                                    <h3 className="titleObjectives">
                                      PARTE {swapConfirm.requires[0].card.name}
                                    </h3>
                                    <p ng-if="!cardsUnidade">
                                      Você está trocando a parte{" "}
                                      {swapConfirm.requires[0].card.name} de
                                      um(a){" "}
                                      {swapConfirm.requires[0].card.description}
                                      . Parece uma boa escolha!
                                    </p>
                                  </div>
                                  <div className="imageUser flex items-center">
                                    {!!user.extra.avatar ? (
                                      <div className="avatarContent">
                                        <UserAvatar
                                          data={{ ...user, ...user?.extra }}
                                          responsive={true}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        src={user.image.small.url}
                                        alt="imgPlayer"
                                        className="imgList"
                                      />
                                    )}
                                    <p className="text-left">
                                      {user.name} <br /> Meu card
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex-wrap-inherit flex-1">
                            {!swapOk && (
                              <>
                                <button
                                  className="createOffer bg-[#4bb313] text-[#FFFFF] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10 ml-5"
                                  onClick={() => {
                                    props.setOpenModal("confirm-swap");
                                  }}
                                >
                                  REALIZAR TROCA
                                </button>
                                <Modal
                                  show={props.openModal === "confirm-swap"}
                                  size="md"
                                  popup
                                  onClose={() => props.setOpenModal(undefined)}
                                >
                                  <Modal.Header />
                                  <Modal.Body>
                                    <div className="text-center">
                                      <TbCards className="mx-auto mb-4 h-20 w-20 text-[#00ADEE]" />
                                      <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                        Você está pertinho de conseguir! <br />
                                        Digite seu CPF e confirme a troca dos
                                        cards.
                                      </h3>
                                      <div className="flex justify-center gap-4">
                                        <form
                                          onSubmit={handleSubmit(
                                            handleFormSubmit(false)
                                          )}
                                        >
                                          <div>
                                            <div className="mb-2 block">
                                              <Label
                                                htmlFor="cpf"
                                                value="Digite seu CPF"
                                              />
                                            </div>
                                            <TextInput
                                              {...register("cpf", {
                                                onChange: (e) =>
                                                  (e.target.value =
                                                    e.target.value.replace(
                                                      /\D+/g,
                                                      ""
                                                    )),
                                              })}
                                              id="cpf"
                                              type="text"
                                              maxLength={11}
                                              required
                                            />{" "}
                                            <br />
                                          </div>
                                          <div className="flex items-center justify-center">
                                            <Button type="submit">
                                              Confirmar
                                            </Button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                </Modal>
                              </>
                            )}
                            {swapOk && (
                              <div className="flex flex-wrap-inherit flex-1">
                                <>
                                  <button
                                    onClick={() => listNovasOfertasVitrine()}
                                    className="createOffer bg-[#062784] text-[#FFFFF] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10 ml-5"
                                  >
                                    VER NOVAS OFERTAS
                                  </button>
                                  <Link
                                    to="/premios"
                                    className="bg-[#062784] text-[#FFFFF] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10 ml-5"
                                  >
                                    VER MEUS CARDS
                                  </Link>
                                </>
                              </div>
                            )}
                          </div>

                          <div className="swap cardOferecido">
                            <div className="">
                              <img
                                src="img/topo_card_off.png"
                                alt=""
                                className="cardOff"
                              />
                              <div className="infoHeader">
                                <div className="desafio">1</div>
                                <p>
                                  PARTE ({swapConfirm.rewards[0].card.name})
                                </p>
                              </div>
                              <div className="front">
                                <div className="cardFront">
                                  <img
                                    src={
                                      swapConfirm.rewards[0].premio.image.medium
                                        .url
                                    }
                                    className="imgBg"
                                    alt=""
                                  />
                                  <img
                                    src={
                                      swapConfirm.rewards[0].card.image.medium
                                        .url
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="infoObjective">
                                  <h3 className="titleObjectives">
                                    PARTE {swapConfirm.rewards[0].card.name}
                                  </h3>
                                  <p ng-if="!cardsUnidade">
                                    Você está trocando a parte{" "}
                                    {swapConfirm.rewards[0].card.name} de um(a){" "}
                                    {swapConfirm.rewards[0].card.description}.
                                    Parece uma boa escolha!
                                  </p>
                                </div>
                                {!swapOk ? (
                                  <>
                                    <div className="imageUser flex items-center">
                                      <img
                                        src="/assets/img-profile.png"
                                        alt=""
                                      />
                                      <p className="text-left">
                                        Conheça após a troca <br /> Card
                                        ofertado
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="imageUser flex items-center">
                                      {!!swapConfirm.avatar ? (
                                        <div className="avatarContent">
                                          <UserAvatar
                                            data={{
                                              ...swapConfirm,
                                              ...swapConfirm.avatar,
                                            }}
                                            responsive={true}
                                          />
                                        </div>
                                      ) : (
                                        <img
                                          src={swapConfirm.image.small.url}
                                          alt="imgPlayer"
                                          className="imgList"
                                        />
                                      )}
                                      <p className="text-left">
                                        {swapConfirm.name} <br /> Card ofertado
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {vitrine.length === 0 && !swapOk && (
                      <div className="text-center text-[#767474] mx-auto my-10  ">
                        <h3 className="text-[1.5em]">Ops!!! :(</h3>
                        <p className="text-[1.2em]">
                          Parece que nossa vitrine precisa de brilho.
                        </p>
                        <p className="text-[1.2em]">
                          Crie uma oferta e deixe seus cards brilharem por aqui.
                        </p>
                        <button
                          className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                          onClick={() => setTabActive("mercado")}
                        >
                          VISITAR MERCADO
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* VITRINE UNIDADE */}
              {tabactive === "vitrineUnidade" && (
                <div className="contentTabs">
                  <div className="targetAbas" id="vitrine">
                    <div className="flex justify-center items-center mb-5">
                      <div className="meuscardsdrop">
                        <img src="img/meus-cards.png" alt="" />
                        <Link to="/premios" className="text-[#c4c4c4]">
                          UNIDADE TEM
                          <strong> {totalStatusMercadoUnidade} CARDS</strong>
                          <i className="glyphicon glyphicon-chevron-down"></i>
                        </Link>
                      </div>
                      <div className="countDown text-[#c4c4c4] ml-10">
                        <div className="countDownNewCards flex">
                          NOVOS CARDS EM{" "}
                          <strong className="ml-2">
                            <LiveCountdown
                              data={new Date(updateTime(new Date()))}
                            />
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerSwaps">
                    {stepVitrineUnidade === 0 &&
                      vitrine &&
                      vitrine.length > 0 &&
                      vitrine.map((item: any) => (
                        <div
                          className="col-xs-12 col-sm-4 listVitrine swap bgSwapList"
                          key={item._id}
                        >
                          <div className="text-center ">
                            <div className="frente">
                              <div className="infoHeader">
                                <div className="imgUser">
                                  <img src="/assets/img-profile.png" alt="" />
                                </div>
                                <p className="text-left">
                                  Conheca após a troca <br />{" "}
                                  <span>Card Ofertado</span>
                                </p>
                              </div>
                              <div className="cardFront">
                                <img
                                  src={item.rewards[0].premio.image.medium.url}
                                  className="imgBg"
                                  alt=""
                                />
                                <img
                                  src={item.rewards[0].card.image.medium.url}
                                  alt=""
                                />
                                <img
                                  src="/assets/rotateIcon.png"
                                  alt=""
                                  className="rotateIcon"
                                  onClick={(event) =>
                                    turnVitrineClickHandler(event)
                                  }
                                  title="girar"
                                />
                              </div>
                              <h3>
                                PARTE <br /> {item.rewards[0].card.name}
                              </h3>
                              <p>
                                <strong>Aproveite a oferta</strong> e garanta a{" "}
                                <strong>
                                  parte {item.rewards[0].card.name}
                                </strong>
                                <br /> de um(a){" "}
                                <strong>
                                  {item.rewards[0].card.description}
                                </strong>
                              </p>
                              <br />
                              <br />
                              <span>
                                Unidade possui o card necessário para negociar.
                              </span>
                              <br />
                              <br />
                              <button
                                className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                                onClick={(event) =>
                                  turnVitrineClickHandler(event)
                                }
                              >
                                GIRE E DESCUBRA
                              </button>
                            </div>
                            <div className="costas">
                              <img
                                src="img/topo_card_off.png"
                                alt=""
                                className="cardOff"
                              />
                              <div className="infoHeader">
                                <div className="imgUser">
                                  <img src="/assets/img-profile.png" alt="" />
                                </div>
                                <p className="text-left">
                                  Conheca após a troca <br />{" "}
                                  <span>Card Desejado</span>
                                </p>
                              </div>
                              <div className="cardFront">
                                <img
                                  src={item.requires[0].premio.image.medium.url}
                                  className="imgBg"
                                  alt=""
                                />
                                <img
                                  src={item.requires[0].card.image.medium.url}
                                  alt=""
                                />
                                <img
                                  src="/assets/rotateIcon.png"
                                  alt=""
                                  className="rotateIcon back"
                                  onClick={(event) =>
                                    rotateIconBackClickHandler(event)
                                  }
                                  title="girar"
                                />
                              </div>
                              <h3>PARTE {item.rewards[0].card.name}</h3>
                              <p>
                                Em troca unidade deseja a{" "}
                                <strong>
                                  parte {item.requires[0].card.name}
                                </strong>
                                <br /> de um(a){" "}
                                <strong>
                                  {item.requires[0].card.description}
                                </strong>
                              </p>
                              <br />
                              <span>
                                <strong>Parece uma boa oferta.</strong> Unidade
                                tem{" "}
                                <strong>
                                  {user.catalog_items[item.requires[0].item]}{" "}
                                  card(s)
                                </strong>{" "}
                                dessa parte. Deseja trocar?
                              </span>
                              <br />
                              <button
                                ng-click="showSwap(obj)"
                                onClick={() => showSwapConfirmUnidade(item)}
                                className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                              >
                                QUERO TROCAR
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}

                    {stepVitrineUnidade === 1 && (
                      <div className="confirmSwap">
                        {!swapUnidadeOk && (
                          <>
                            <div className="text-left">
                              <button
                                className="ml-6 p-2 px-8 text-[#FFF] bg-[#062784] flex items-center"
                                onClick={() => setStepVitrineUnidade(0)}
                              >
                                <FaChevronLeft className="mr-2" />
                                VOLTAR
                              </button>
                            </div>
                            <div className="text-center">
                              <h2>
                                Unidade está muito perto da peça que deseja!
                              </h2>
                            </div>
                          </>
                        )}
                        {swapUnidadeOk && (
                          <div className="text-center">
                            <h2>Parabéns, a unidade conseguiu!</h2>
                          </div>
                        )}
                        <div className="flex flex-wrap">
                          <div className="swap meuCard">
                            <div className="">
                              <div className="">
                                <div className="infoHeader">
                                  <div className="desafio">1</div>
                                  <p>
                                    PARTE (
                                    {swapConfirmUnidade.requires[0].card.name})
                                  </p>
                                </div>
                                <div className="front">
                                  <div className="cardFront">
                                    <img
                                      src={
                                        swapConfirmUnidade.requires[0].premio
                                          .image.medium.url
                                      }
                                      className="imgBg"
                                      alt=""
                                    />
                                    <img
                                      src={
                                        swapConfirmUnidade.requires[0].card
                                          .image.medium.url
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="infoObjective">
                                    <h3 className="titleObjectives">
                                      PARTE{" "}
                                      {swapConfirmUnidade.requires[0].card.name}
                                    </h3>
                                    <p ng-if="!cardsUnidade">
                                      A unidade está trocando a parte{" "}
                                      {swapConfirmUnidade.requires[0].card.name}{" "}
                                      de um(a){" "}
                                      {
                                        swapConfirmUnidade.requires[0].card
                                          .description
                                      }
                                      . Parece uma boa escolha!
                                    </p>
                                  </div>
                                  <div className="imageUser flex items-center">
                                    <img src="/assets/img-profile.png" alt="" />
                                    <p className="text-left">
                                      {teamName} <br /> Card da unidade
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex-wrap-inherit flex-1">
                            {!swapUnidadeOk && (
                              <>
                                <button
                                  className="createOffer bg-[#4bb313] text-[#FFFFF] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10 ml-5"
                                  onClick={() => {
                                    props.setOpenModal("confirm-swap-unidade");
                                  }}
                                >
                                  REALIZAR TROCA
                                </button>
                                <Modal
                                  show={
                                    props.openModal === "confirm-swap-unidade"
                                  }
                                  size="md"
                                  popup
                                  onClose={() => props.setOpenModal(undefined)}
                                >
                                  <Modal.Header />
                                  <Modal.Body>
                                    <div className="text-center">
                                      <TbCards className="mx-auto mb-4 h-20 w-20 text-[#00ADEE]" />
                                      <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                        A unidade está pertinho de conseguir!{" "}
                                        <br />
                                        Digite seu CPF e confirme a troca dos
                                        cards.
                                      </h3>
                                      <div className="flex justify-center gap-4">
                                        <form
                                          onSubmit={handleSubmit(
                                            handleFormSubmit(true)
                                          )}
                                        >
                                          <div>
                                            <div className="mb-2 block">
                                              <Label
                                                htmlFor="cpf"
                                                value="Digite seu CPF"
                                              />
                                            </div>
                                            <TextInput
                                              {...register("cpf", {
                                                onChange: (e) =>
                                                  (e.target.value =
                                                    e.target.value.replace(
                                                      /\D+/g,
                                                      ""
                                                    )),
                                              })}
                                              id="cpf"
                                              type="text"
                                              maxLength={11}
                                              required
                                            />{" "}
                                            <br />
                                          </div>
                                          <div className="flex items-center justify-center">
                                            <Button type="submit">
                                              Confirmar
                                            </Button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                </Modal>
                              </>
                            )}
                            {swapUnidadeOk && (
                              <div className="flex flex-wrap-inherit flex-1">
                                <>
                                  <button
                                    onClick={() =>
                                      listNovasOfertasVitrine(true)
                                    }
                                    className="createOffer bg-[#062784] text-[#FFFFF] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10 ml-5"
                                  >
                                    VER NOVAS OFERTAS
                                  </button>
                                  <Link
                                    to="/premios"
                                    className="bg-[#062784] text-[#FFFFF] text-center py-1 md:px-4 md:py-2 hover:text-white no-underline uppercase my-10 ml-5"
                                  >
                                    VER CARDS UNIDADE
                                  </Link>
                                </>
                              </div>
                            )}
                          </div>

                          <div className="swap cardOferecido">
                            <div className="">
                              <img
                                src="img/topo_card_off.png"
                                alt=""
                                className="cardOff"
                              />
                              <div className="infoHeader">
                                <div className="desafio">1</div>
                                <p>
                                  PARTE (
                                  {swapConfirmUnidade.rewards[0].card.name})
                                </p>
                              </div>
                              <div className="front">
                                <div className="cardFront">
                                  <img
                                    src={
                                      swapConfirmUnidade.rewards[0].premio.image
                                        .medium.url
                                    }
                                    className="imgBg"
                                    alt=""
                                  />
                                  <img
                                    src={
                                      swapConfirmUnidade.rewards[0].card.image
                                        .medium.url
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="infoObjective">
                                  <h3 className="titleObjectives">
                                    PARTE{" "}
                                    {swapConfirmUnidade.rewards[0].card.name}
                                  </h3>
                                  <p ng-if="!cardsUnidade">
                                    A unidade está trocando a parte{" "}
                                    {swapConfirmUnidade.rewards[0].card.name} de
                                    um(a){" "}
                                    {
                                      swapConfirmUnidade.rewards[0].card
                                        .description
                                    }
                                    . Parece uma boa escolha!
                                  </p>
                                </div>
                                {!swapUnidadeOk ? (
                                  <>
                                    <div className="imageUser flex items-center">
                                      <img
                                        src="/assets/img-profile.png"
                                        alt=""
                                      />
                                      <p className="text-left">
                                        Conheça após a troca <br /> Card
                                        ofertado
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="imageUser flex items-center">
                                      <img
                                        src="/assets/img-profile.png"
                                        alt=""
                                      />
                                      <p className="text-left">
                                        {getNameUnidade(
                                          swapConfirmUnidade.name
                                        )}{" "}
                                        <br /> Card ofertado
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {vitrine.length === 0 && !swapUnidadeOk && (
                      <div className="text-center text-[#767474] mx-auto my-10  ">
                        <h3 className="text-[1.5em]">Ops!!! :(</h3>
                        <p className="text-[1.2em]">
                          Parece que a vitrine da unidade precisa de brilho.
                        </p>
                        <p className="text-[1.2em]">
                          Crie uma oferta da unidade e deixe os cards brilharem
                          por aqui.
                        </p>
                        <button
                          className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                          onClick={() => setTabActive("mercado")}
                        >
                          VISITAR MERCADO
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <RegulamentoFooter />
      </div>
      <Outlet />
      <ModalSelecionarCard />
      <ModalSelecionarCardDesejo />
      <ModalEditCard1 />
      <ModalEditCard2 />
      <ModalSwapCard />
    </>
  );
}
