/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { UserInfo } from "components/user-info";

import "./styles.scss";
import { NavLink } from "react-router-dom";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import { getHallFama } from "services/hallFama.service";
import { RegulamentoFooter } from "components/regulamento-footer";

export function HallDaFama() {
  const { user } = useAuth();
  const { showModal } = useModal();
  const [hallFama, setHallFama] = useState<any>();

  useEffect(() => {
    if (user) {
      const completed = user.extra.avatar || user.image;
      if (!completed) {
        showModal("avatar-challenge");
      }

      getHallFama().then(function (data) {
        setHallFama(data[0]);
      });
    }
  }, [user]);

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:pt-10">
          <NavMenu />

          <div className="w-full flex justify-center p-2 md:p-0">
            <UserInfo />
          </div>
          <div className="w-screen" id="hallFama">
            <div className="">
              {hallFama && (
                <>
                  {/* <h2 dangerouslySetInnerHTML={{ __html: objetivo.title }}></h2> */}
                  <div
                    className="flex justify-center p-2 md:p-0 md:pt-10"
                    dangerouslySetInnerHTML={{ __html: hallFama.html }}
                  ></div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="bg-[#081c5a]">
          <RegulamentoFooter />
        </div>
      </div>
      <Outlet />
    </>
  );
}
