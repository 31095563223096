import { api } from "./api";

export async function getTermosDeUso() {
  const payload = [{ $sort: { created: -1 } }, { $limit: 1 }] as any;

  payload.unshift({
    $match: {
      active: true,
    },
  });


  const { data } = await api.post(`database/termos_de_uso__c/aggregate`, payload, { headers: { Authorization: "Basic YnJiX3YyX2htbDo2M2YzOGQzOGI2OTIxZDQ5NjI0NTFjM2M=", "content-type": "application/json", "cache-control": "no-cache" } });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function updateTermosDeUso(user: any) {
    const { data } = await api.put(`database/player`, user);
    return data;
}