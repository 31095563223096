import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { getTermosDeUso } from "services/terms.service";

import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";

export function TermosDeUsoModal() {
  const { user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();
  const [termo, setTermo] = useState<any>();

  const modalID = "modal-termos-de-uso";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  function handleShowAvatar() {
    //
  }

  useEffect(() => {
    getTermosDeUso().then(function (data) {
      setTermo(data[0]);
    });
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalVideo">
            <div className="bg-white rounded-xl p-3 flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>
              <h3 className="text-lg text-blue-500 font-bold">TERMOS DE USO</h3>

              <div className="md:w-[1000px] flex flex-col md:flex-row space-y-16 md:space-x-6 md:space-y-0 md:items-start md:justify-center">
                <div className="flex-1 flex flex-col">
                  <div className="w-full max-w-[1024px] rounded bg-white p-6 max-h-[400px] overflow-y-auto">
                    <div className="">
                      {termo && (
                        <>
                          <div
                            className="w-full"
                            dangerouslySetInnerHTML={{ __html: termo.html }}
                          ></div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-3 relative z-10 w-full flex flex-col space-y-2"></div>
            </div>
          </div>
        )}
      />
    </>
  );
}
