import { useAuth } from "context/AuthContext";
import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";

import Target from "assets/img/target-icon.svg";
import TrophyDark from "assets/img/trophy-dark.svg";
import { getMetas } from "services/dashboard.service";
import {
  formatNumber,
  handlePercent,
  handlePercentBar,
  handlePercentBarCss,
} from "../ultils";

export function PeriodItem() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [dataQuarter, setDataQuarter] = useState<any>([]);

  useEffect(() => {
    async function loadData() {
      if (!!user?._id) {
        const data = await getMetas();

        setData(data.filter((e: any) => e.unidade === "Trimestre"));
        setDataQuarter(data.filter((e: any) => e.unidade !== "Trimestre"));

        setLoading(false);
      }
    }

    loadData();
  }, [user]);

  return (
    <>
      {!loading ? (
        <div className="indicadores w-full h-full">
          <div className="top-img img-down">
            <img src={Target} alt="Icone de regularidade" />
          </div>
          <div>
            <div className="title">
              <h3>
                METAS DO <span>BRB</span>
              </h3>
              {data?.length === 0 && (
                <p className="font-bold text-white text-lg">
                  <strong>Atualização em breve</strong>
                </p>
              )}
            </div>

            <div className="grid  md:grid-cols-2 space-y-10 md:space-y-0 gap-x-10 mt-10">
              <div className={"space-y-10"}>
                {!!data && data.length > 0 ? (
                  <>
                    {data.map((item: any, index: number) => (
                      <div className="w-full bar" key={index}>
                        <p className="-mb-4 uppercase">
                          {item.tempo}º {item.unidade}
                        </p>
                        <div>
                          {!!formatNumber(item.total) && (
                            <div
                              className="progress"
                              style={{
                                width: handlePercentBar(item.total) + "%",
                              }}
                            >
                              {formatNumber(item.total)}
                            </div>
                          )}

                          <span
                            style={{
                              right:
                                handlePercentBarCss(item.percent_meta) + "%",
                            }}
                          >
                            {handlePercent(item.percent_meta)}
                          </span>

                          <div className="trophy">
                            <img src={TrophyDark} alt="Trofeu" />
                            <p>{item.meta}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {["2", "3", "4"].map((item, index) => (
                      <div className="w-full bar" key={index}>
                        <p className="-mb-4 uppercase">{item}º Trimestre</p>

                        <div>
                          <div
                            className="progress"
                            style={{
                              width: "2%",
                            }}
                          ></div>

                          <span
                            style={{
                              right: "2%",
                            }}
                          ></span>

                          <div className="trophy">
                            <img src={TrophyDark} alt="Trofeu" />
                            <p></p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>

              <div className={"space-y-10"}>
                {!!dataQuarter && dataQuarter.length > 0 ? (
                  <>
                    {dataQuarter
                      .sort((a: any, b: any) =>
                        b.unidade.localeCompare(a.unidade)
                      )
                      .map((item: any, index: number) => (
                        <div className="w-full bar" key={index}>
                          <p className="-mb-4 uppercase">
                            {item.tempo}º {item.unidade}
                          </p>
                          <div>
                            {!!formatNumber(item.total) && (
                              <div
                                className="progress"
                                style={{
                                  width: handlePercentBar(item.total) + "%",
                                }}
                              >
                                {formatNumber(item.total)}
                              </div>
                            )}

                            <span
                              style={{
                                right:
                                  handlePercentBarCss(item.percent_meta) + "%",
                              }}
                            >
                              {handlePercent(item.percent_meta)}
                            </span>

                            <div className="trophy">
                              <img src={TrophyDark} alt="Trofeu" />
                              <p>{item.meta}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                ) : (
                  <>
                    {["Semestral", "Anual"].map((item, index) => (
                      <div className="w-full bar" key={index}>
                        <p className="-mb-4 uppercase">{item}</p>
                        <div>
                          <div
                            className="progress"
                            style={{
                              width: "2%",
                            }}
                          ></div>

                          <span
                            style={{
                              right: "2%",
                            }}
                          ></span>

                          <div className="trophy">
                            <img src={TrophyDark} alt="Trofeu" />
                            <p></p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner className="mt-12 mb-8" />
      )}
    </>
  );
}
