import { api } from "./api";


export async function listMySwaps(userId: any, unidade: any) {
  const payload = [
    {"$match":{ "$or": [{"seller": userId}, {"seller": unidade}]  }},
    { "$facet": {
        "p1": [ 
            { "$match": { "buyer":{"$exists":true} }},
            { "$lookup": {"from":"player", "localField":"buyer", "foreignField":"_id", "as":"p" }},
            { "$unwind" : "$p" },
            { "$project": { "name":"$p.name", "image":"$p.image", "avatar":"$p.extra.avatar", "seller":1, "buyer":1, "extra":1, "requires":1, "rewards":1, "created":1, "acquired":1 }}
        ],
        "p2": [ 
          { "$match": { "buyer":{"$exists":true} }},
          { "$lookup": { "from": "team", "localField": "buyer", "foreignField": "_id", as: "p"} },
          { "$unwind": "$p" },
          { "$project": { "name": "$p.name",  "seller": 1, "buyer": 1, "extra": 1, "requires": 1, "rewards": 1, "created": 1, "acquired": 1} },
        ],
        "p3": [{ "$match": { "buyer": { "$exists": false } } }],
    }},
    { "$project": { "all": { "$concatArrays": [ "$p1", "$p2", "$p3" ] } } },
    { "$unwind" : "$all" },
    { "$replaceRoot": { "newRoot": "$all" }}
  ];

  const { data } = await api.post("/database/swap/aggregate", payload );
  return data;
}

export async function getSwapPayment(idSwap: string) {
  try {
    const payload = {
      "swap": idSwap
    };
    const { data } = await api.post("/swap/receive", payload);
    return data;
  } catch (error) {
    return error;
  }
}

export async function deleteSwap(idSwap: string, userId: string) {
  const payload = {
    _id: idSwap,
    player: userId,
  };
  try {
    const { data } = await api.post(`/database/delete_swap__c`, payload);
    return data;
  } catch (error) {
    return error;
  }
}

export async function creteNewSwapChange(swap: any) {
  try {
    const { data } = await api.post("/swap", swap);
    return data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      console.log("Erro 401: UNAUTHORIZED.");
      return error.response.data;
    }
  }
}

export async function loadTotalStatusMercado(userId: string) {
  const payload = [
    {"$match":{ "type":2, "player": userId  }},
    {"$group":{ "_id":"$item", "total":{"$sum":"$total"}}},
    {"$lookup":{"from":"catalog_item", "localField":"_id", "foreignField":"_id", "as":"ci"}},
    {"$unwind": "$ci" },
    {"$project":{ "type":"$ci.catalogId", "total":1 } },
    {"$group":{ "_id":"$type", "total":{"$sum":"$total"}}},
    {"$sort":{ "total":-1 }}
  ];

  const { data } = await api.post("/database/achievement/aggregate", payload);
  return data;
}

export async function listGlobalSwaps(userId: string, itens: any, isListUnidade?: any) {
  let my_items: any[] = [];
  
  Object.entries(itens).forEach(function (o: any) {
    if(o[1] > 0) { 
        my_items.push(o[0]);
    }
  });

  let payload = [];
  if(!isListUnidade){
    payload = [
      { "$match":{ "seller":{"$ne":userId}, "buyer":{"$exists":false}, "requires.item":{"$in":my_items}, "created":{"$lt":{ "$date": "-0d+"}} }},
      { "$sort":{ "created":-1 }},
      { "$lookup":{ "from":"player", "localField":"seller", "foreignField":"_id", "as":"p" }},
      { "$unwind": "$p" },
      { "$project":{ "seller":1, "name":"$p.name", "image":"$p.image", "avatar":"$p.extra.avatar", "requires":1 ,"rewards":1, "extra":1, "created":1, "buyer":1 }},
      { "$limit": 100 }
    ];
  } else {
    payload = [
      { "$match":{ "seller":{"$ne":userId}, "buyer":{"$exists":false}, "requires.item":{"$in":my_items}, "created":{"$lt":{ "$date": "-0d+"}} }},
      { "$sort":{ "created":-1 }},
      { "$lookup":{ "from":"team", "localField":"seller", "foreignField":"_id", "as":"p" }},
      { "$unwind": "$p" },
      { "$project":{ "seller":1, "name":"$p.name", "requires":1 ,"rewards":1, "extra":1, "created":1, "buyer":1 }},
      { "$limit": 100 }
    ];
  }


  const { data } = await api.post("/database/swap/aggregate", payload);
  return data;
}


export async function acquireGlobalSwap(userId: string, item: any) {
  try {
    const payload = {
      swap: item._id,
      buyer: userId,
    };

    const { data } = await api.post("/swap/acquire", payload);
    return data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      console.log("Erro 401: UNAUTHORIZED.");
    }
    return error;
  }
}

export async function loadTeamStatus(idAgencia: string) {
  try {
    const payload = [
      {$match:{ player: idAgencia, type: 2}},
      {$group:{ _id: "$item", total:{$sum: "$total"}}},
    ];

    const { data } = await api.post("/database/achievement/aggregate", payload);
    return data;
  } catch (error: any) {
    return error;
  }
}