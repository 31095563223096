export function formatNumber(num: number) {
  if (!num) {
    return 0;
  } else if (num < 1000) {
    return num.toFixed(1);
  } else {
    return (num / 1000).toFixed(1) + "k";
  }
}

export function handlePercent(num: any) {
  if (!!!num) return 100;

  if (num === 0 || num === "0") return 100;

  return parseInt(num) > 100 ? 0 : 100 - parseInt(num);
}

export function handlePercentBar(num: any) {
  if (!num) return 0;

  if (num - 10 < 9) return 10;

  return num - 10 >= 100 ? 100 : num - 10;
}

export function handlePercentBarCss(num: any) {
  if (handlePercent(num) - 15 < 0) return 0;

  return handlePercent(num) - 15;
}
