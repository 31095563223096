import { HtmlHTMLAttributes, useEffect, useState } from "react";
import { useAuth } from "context/AuthContext";
import { getRegulamento } from "services/regulamento.service";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useLocation } from "react-router-dom";

import "./styles.scss";

export function RegulamentoFooter() {
  const { user } = useAuth();
  const [regulamento, setRegulamento] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (user) {
      loadRegulamento();
    }

    async function loadRegulamento() {
      const data = await getRegulamento();
      // const ruledata = data.filter((item) =>{ return {item.type == 'campanha'} })
      setRegulamento(data[0]);
    }
  }, [user]);

  const downloadDocument = () => {
    window.open(regulamento.document.url);
  };

  const toggleRegulation = (e: any) => {
    const element = document.getElementById("regulamentoFooter");

    if (!element) return;
    if (element.classList.contains("close")) {
      element.classList.remove("close");
      element.classList.add("open");
      setShow(true);
    } else {
      element.classList.remove("open");
      element.classList.add("close");
      setShow(false);
    }
  };

  return (
    <>
      <div id="regulamentoFooter" className="close">
        <div className="text-center pt-7 pb-10">
          <div className="">
            <div>
              {(location.pathname === "/inicio" ||
                location.pathname === "/como-ganhar") && (
                <button
                  className="bg-[#88E1F2] text-[#04227C] w-[45%] py-4 font-bold uppercase"
                  onClick={(e) => {
                    toggleRegulation(e);
                  }}
                >
                  confira o regulamento
                </button>
              )}
            </div>
          </div>

          <div className="text-center text-white">
            <p className="mt-6">BRB Vai Pra Cima 2023</p>
            <ul className="list-disc mt-4">
              <li>
                Todos os ingressos são meramente ilustrativos. As premiações e
                demais regras da campanha deverão seguir os critérios divulgados
                no{" "}
                <a
                  onClick={(e) => {
                    toggleRegulation(e);
                  }}
                  className="link"
                >
                  regulamento
                </a>
                .
              </li>
            </ul>
          </div>
        </div>

        <div className="content">
          <div>
            <button
              type="button"
              className="closeRegulation"
              onClick={(e) => {
                toggleRegulation(e);
              }}
            >
              X
            </button>

            <h2>REGULAMENTO</h2>

            <Scrollbars
              style={{ width: "100%" }}
              className="contentExtrato"
              renderTrackVertical={(props) => (
                <div {...props} className="track-vertical" />
              )}
            >
              <div
                className="w-full"
                dangerouslySetInnerHTML={{ __html: regulamento?.html }}
              ></div>
            </Scrollbars>
          </div>

          <button className="download" onClick={downloadDocument}>
            Download do regulamento <i className="gg-software-download"></i>
          </button>
        </div>
      </div>
    </>
  );
}
