import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";
import { PiStarThin } from "react-icons/pi";
import { BiSolidLockAlt } from "react-icons/bi";
import { TbCards } from "react-icons/tb";

import "./styles.scss";

export function ModalEditCard2() {
  const { user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();

  // Função chamada quando um item é selecionado na modal
  const handleItemSelection = (selectedItemId: any) => {
    // Chame a função para atualizar o valor de initialSwap.rewards.item no componente pai
    // Você pode passar o ID do item selecionado como argumento
    modalData.addItemToRewards(selectedItemId);
  };

  const modalID = "modal-edit-card-2";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  if (!user) return null;

  if (!modalData || modalData?.length < 1) return null;

  const cards = modalData.cards;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalSelecionarCardEdit1">
            <div className="bg-white flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>
              <h2 className="text-[1.6em]">
                Selecione o card que você quer ofertar.
              </h2>
              <div className="text-center containerSwaps md:w-[950px]  premios">
                {cards
                  ?.filter((e: any) => {
                    if (e.catalogId === "premios" && e.requires.length > 1) {
                      return e;
                    }
                    return false;
                  })
                  .map((item: any) => (
                    <div className="swap" key={item._id}>
                      <div className="front">
                        <div className="infoHeader">
                          <div className="desafio">1</div>
                          <p>{item.name}</p>
                        </div>
                        <div className="cardFront">
                          <img
                            src={item.image.medium.url}
                            className="cardsIWant"
                            alt=""
                          />

                          {/* {item.requires.map((r: any, i: number) => (
                                  <div key={i}>
                                    <img
                                      ng-if="r.item != newSwap.rewards[0].item"
                                      src={getItem(r.item).image.medium.url}
                                      alt=""
                                    />
                                  </div>
                                ))} */}
                        </div>
                        <div className="">
                          <p>
                            <br />
                          </p>
                          <button
                            className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                            onClick={() => modalData.showModalCardsDesejo(item)}
                          >
                            VER CARDS
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
