import { api } from "./api";

export async function getAllRoletas() {
  const payload = [{ $match: { "extra.active": true } }] as any;

  const { data } = await api.post(`database/mystery_box/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getRoleta(id: string) {
    const { data } = await api.get(`mystery/${id}`);
    return data;
}	


export async function evaluateMystery(id: any, userId: any) {
    const { data } = await api.get(`mystery/evaluate/${id}?player=${userId}`);
    return data;
}	

export async function executeMystery(id: any, userId: any) {
    const { data } = await api.get(`mystery/execute/${id}?player=${userId}`);
    return data;
}

