import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";

type LiveCountdownProps = {
  data: Date;
};

const LiveCountdown: React.FC<LiveCountdownProps> = ({ data }) => {
  const wrapRef = useRef(null);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (wrapRef.current) {
      setLoaded(true);
    }
  }, []);

  const leadingZeros = (num: any) => {
    return (num < 10 ? "0" : "") + num;
  };

  type CountdownRendererProps = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
  }: CountdownRendererProps) => (
    <div className="timer">
      {leadingZeros(hours)}h {leadingZeros(minutes)}m {leadingZeros(seconds)}s
    </div>
  );

  return (
    <div
      ref={wrapRef}
      className={`sdn-live-countdown-wrap ${isLoaded ? "loaded" : ""}`}
    >
      {isLoaded && (
        <Countdown
          date={data.setDate(data.getDate() + 1)}
          renderer={renderer}
        />
      )}
    </div>
  );
};

export default LiveCountdown;
