/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { UserInfo } from "components/user-info";

import "./styles.scss";
import { NavLink } from "react-router-dom";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import { getConvencao } from "services/convencao.service";
import { RegulamentoFooter } from "components/regulamento-footer";

export function Convencao() {
  const { user } = useAuth();
  const { showModal } = useModal();
  const [convencao, setConvencao] = useState<any>();

  useEffect(() => {
    if (user) {
      // console.log(user.challenges);
      const completed = user.extra.avatar || user.image;
      if (!completed) {
        showModal("avatar-challenge");
      }

      getConvencao().then(function (data) {
        setConvencao(data[0]);
      });

      setTimeout(() => {
        const scriptElement = document.getElementById("myScript");
        const scriptElement1 = document.getElementById("myScript1");
        const scriptElement2 = document.getElementById("myScript2");
        const scriptElement3 = document.getElementById("myScript3");
        const scriptElement4 = document.getElementById("myScript4");
        const scriptElement5 = document.getElementById("myScript5");
        const scriptElement6 = document.getElementById("myScript6");
        if (
          scriptElement &&
          scriptElement1 &&
          scriptElement2 &&
          scriptElement3 &&
          scriptElement4 &&
          scriptElement5 &&
          scriptElement6
        ) {
          const script = scriptElement.innerHTML;
          const script1 = scriptElement1.innerHTML;
          const script2 = scriptElement2.innerHTML;
          const script3 = scriptElement3.innerHTML;
          const script4 = scriptElement4.innerHTML;
          const script5 = scriptElement5.innerHTML;
          const script6 = scriptElement6.innerHTML;
          window.eval(script);
          window.eval(script1);
          window.eval(script2);
          window.eval(script3);
          window.eval(script4);
          window.eval(script5);
          window.eval(script6);
        }
      }, 2000);
    }
  }, [user]);

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:pt-10">
          <NavMenu />

          <div className="w-full flex justify-center p-2 md:p-0">
            <UserInfo />
          </div>
          <div className="w-screen" id="objetivo">
            <div className="">
              {convencao && (
                <>
                  {/* <h2 dangerouslySetInnerHTML={{ __html: objetivo.title }}></h2> */}
                  <div
                    className="w-full"
                    dangerouslySetInnerHTML={{ __html: convencao.html }}
                  ></div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="bg-[#081c5a]">
          <RegulamentoFooter />
        </div>
      </div>
      <Outlet />
    </>
  );
}
