import { useAuth } from "context/AuthContext";
import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";

import Person from "assets/img/person-icon.svg";
import TrophyLine from "assets/img/trophy-line.svg";
import { getBateuLevou } from "services/dashboard.service";
import moment from "moment";
import {
  formatNumber,
  handlePercent,
  handlePercentBar,
  handlePercentBarCss,
} from "../ultils";

export function GraphItem() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();

  useEffect(() => {
    async function loadData() {
      if (!!user?._id) {
        const data = await getBateuLevou(user._id);
        setData(data);
        setLoading(false);
      }
    }

    if (!!user?._id) {
      loadData();
    }
  }, [user]);

  return (
    <>
      {!loading ? (
        <div className="indicadores h-full">
          <div className="flex space-x-10">
            <div>
              <h3>bateu, levou</h3>

              <p>
                Se a sua unidade fechar o mês no azul, seu time ganha
                <b> 3 mil pontos no Mundo BRB</b> e você ganha um <b>card</b>.
              </p>
            </div>
            <div>
              <h3>superação</h3>

              <p>
                Se você superar 110 pontos, seu time ganha{" "}
                <b> 3 mil pontos no Mundo BRB,</b> e você ganha um <b>card</b>
              </p>
            </div>
          </div>

          {!!data && data.total && formatNumber(data.total) ? (
            <>
              <div className="w-full bar mt-28">
                {data?.mes && (
                  <p className="uppercase">
                    {moment()
                      .month(data.mes - 1)
                      .format("MMMM")}
                    /{data.ano}
                  </p>
                )}
                <div>
                  {!!data && data.total && formatNumber(data.total) ? (
                    <div
                      className="progress"
                      style={{ width: handlePercentBar(data.total) + "%" }}
                    >
                      {formatNumber(data.total)}
                    </div>
                  ) : (
                    <div className="progress" style={{ width: "0%" }}>
                      0
                    </div>
                  )}

                  {!!data && data.percent_meta ? (
                    <span
                      style={{
                        right: handlePercentBarCss(data.percent_meta) + "%",
                      }}
                    >
                      {handlePercent(data.percent_meta)}
                    </span>
                  ) : (
                    <></>
                  )}

                  <div className="bar-end"></div>

                  <div className="bateu">
                    <p>Bateu, levou!</p>
                    <div className="img">
                      <picture>
                        <img src={TrophyLine} alt="Icone de trofeu" />
                      </picture>
                      <span>{!!data && !!data.meta ? data.meta : 0}</span>
                    </div>
                  </div>
                  <div className="superou">
                    <p>superação</p>
                    <div className="img">
                      <picture>
                        <img src={Person} alt="Icone de trofeu" />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full bar mt-28">
                <p className="uppercase font-bold">Atualização em breve</p>
              </div>
              <div>
                <div className="w-full bg-[#081d5c] h-11">
                  <div className="bar bg-[#88e1f2] w-2 h-full"></div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <Spinner className="mt-12 mb-8" />
      )}
    </>
  );
}
