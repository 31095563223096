import { api } from "./api";

export async function getBateuLevou(
  player: string,
  ano?: string
): Promise<any[]> {
  try {
    const { data } = await api.post(`find/bateu_levou`, { player, ano });

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data[0];
    }
  } catch (e) {
    throw e;
  }
}

export async function getRegularidade(
  player: string,
  ano?: string
): Promise<any[]> {
  try {
    const { data } = await api.post(`find/regularidade`, { player, ano });

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data[0];
    }
  } catch (e) {
    throw e;
  }
}

export async function getMetas(ano?: string): Promise<any[]> {
  try {
    const { data } = await api.post(`find/metas_brb`, { ano });

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}
