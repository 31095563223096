import axios from 'axios'

import { setupInterceptors } from './interceptors'

export function getAPIClient(ctx?: any) {

	const api = setupInterceptors(axios.create({
		baseURL: process.env.REACT_APP_API_URL
	}))
	
	return api
}
