import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";
import { PiStarThin } from "react-icons/pi";
import { BiSolidLockAlt } from "react-icons/bi";
import { TbCards } from "react-icons/tb";

import "./styles.scss";

export function ModalEditCard1() {
  const { user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();

  const modalID = "modal-edit-card-1";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  if (!user) return null;

  if (!modalData || modalData?.length < 1) return null;

  const cards = modalData.cards;

  function verifyCardUnidade(e: any) {
    if (modalData.itemUnidade) {
      return modalData.totTeamRequirement(e) >= 1;
    } else {
      return modalData.totRequirement(e) >= 1;
    }
  }

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalSelecionarCardEdit1">
            <div className="bg-white flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>
              {modalData.itemUnidade ? (
                <h2 className="text-[1.6em]">
                  Selecione o card que sua unidade quer ofertar.
                </h2>
              ) : (
                <h2 className="text-[1.6em]">
                  Selecione o card que você quer ofertar.
                </h2>
              )}
              <div className="text-center containerSwaps md:w-[950px]">
                {cards
                  ?.filter((e: any) => {
                    if (
                      e.catalogId === "premios" &&
                      verifyCardUnidade(e) &&
                      e.requires.length > 1
                    ) {
                      return e;
                    }
                    return false;
                  })
                  .map((item: any) => (
                    <div className="swap" key={item._id}>
                      <div className="front">
                        <div className="infoHeader">
                          <div className="desafio">1</div>
                          <p>{item.name}</p>
                        </div>
                        <div className="cardFront">
                          <img
                            src={item.image.medium.url}
                            className="imgBg"
                            alt=""
                          />
                          {item.requires.map((r: any, i: number) => (
                            <div key={i}>
                              {item.cardUnidade ? (
                                <span>
                                  {modalData.isTeamRequirementUnlocked(r) && (
                                    <img
                                      src={
                                        modalData.getItem(r.item).image.medium
                                          .url
                                      }
                                      alt=""
                                    />
                                  )}
                                </span>
                              ) : (
                                <span>
                                  {modalData.isRequirementUnlocked(r) && (
                                    <img
                                      src={
                                        modalData.getItem(r.item).image.medium
                                          .url
                                      }
                                      alt=""
                                    />
                                  )}
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="verMais">
                          <div className="progressCard">
                            {item.cardUnidade ? (
                              <span>
                                {modalData.totTeamRequirementOk(item)}
                                <span>/{item.requires.length}</span>
                              </span>
                            ) : (
                              <span>
                                {modalData.totRequirementOk(item)}
                                <span>/{item.requires.length}</span>
                              </span>
                            )}
                          </div>
                          <div className="">
                            <TbCards />
                          </div>
                          <div className="text-[1.3em] text-[#062784]">
                            {item.cardUnidade ? (
                              <p>Unidade tem</p>
                            ) : (
                              <p>Eu tenho</p>
                            )}
                            <p>
                              {item.cardUnidade ? (
                                <strong>
                                  {modalData.totTeamRequirement(item)}
                                </strong>
                              ) : (
                                <strong>
                                  {modalData.totRequirement(item)}
                                </strong>
                              )}{" "}
                              card(s)
                            </p>
                          </div>
                          <div className="">
                            <button
                              className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                              onClick={() => modalData.showModalCards(item)}
                            >
                              VER CARDS
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
