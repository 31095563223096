import { useAuth } from "context/AuthContext";
import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";

import Task from "assets/img/task-icon.svg";
import { getRegularidade } from "services/dashboard.service";
import moment from "moment";
import { FaCheck } from "react-icons/fa";

export function DateItem() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();

  useEffect(() => {
    async function loadData() {
      if (!!user?._id) {
        const data = await getRegularidade(user._id);
        setData(data);
        setLoading(false);
      }
    }

    loadData();
  }, [user]);

  return (
    <>
      {!loading ? (
        <div className="indicadores h-full">
          <div className="top-img">
            <img src={Task} alt="Icone de regularidade" />
          </div>
          <div>
            <h3>REGULARIDADE</h3>

            <p>
              Se a unidade fechar o mês no azul, por <b>2 meses seguidos,</b> o
              seu time ganha <b>3 mil pontos pra gastar no Mundo BRB.</b> Você
              coleciona mais um <b>card!</b>
            </p>

            <p className="mt-14">SEUS RESULTADOS</p>

            {!data && (
              <div className="border text-center mt-4 px-2 py-7">
                Em breve você poderá acompanhar os resultados por aqui
              </div>
            )}

            {!!data && data.percent_meta < 100 ? (
              <div className="bg-[#081D5C] text-center mt-4 px-2 py-7">
                Você ainda não conquistou nenhum prêmio de regularidade.
              </div>
            ) : (
              <></>
            )}

            {!!data && data.percent_meta >= 100 ? (
              <div className="mt-5 grid grid-cols-3 gap-2">
                <div className="meses relative concluido">
                  {data.mes
                    .sort((a: number, b: number) => a - b)
                    .map((item: number) => (
                      <div key={item}>
                        <p>
                          {moment()
                            .month(item - 1)
                            .format("MMM")
                            .toUpperCase()}
                        </p>
                      </div>
                    ))}

                  <FaCheck
                    className="absolute left-1/2 transform -translate-x-1/2 -bottom-2 text-[#88E1F2]"
                    size={25}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <Spinner className="mt-12 mb-8" />
      )}
    </>
  );
}
