import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";
import { PiStarThin } from "react-icons/pi";
import { BiSolidLockAlt } from "react-icons/bi";

import "./styles.scss";

export function ModalSwapCard() {
  const { user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();

  const modalID = "modal-swap-card";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  if (!user) return null;

  if (!modalData || modalData?.length < 1) return null;

  const cards = modalData.cards;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalSelecionarCard">
            <div className="bg-white flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>
              <h2>Confirme sua troca.</h2>
              <p></p>
              <div className="text-center containerSwaps">
                <h2>texto</h2>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
