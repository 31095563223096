import { useEffect, useState } from "react";
import { NavMenu } from "components/navmenu";
import { UserInfo } from "components/user-info";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router";
import { useAuth } from "context/AuthContext";
import { getTermosDeUso, updateTermosDeUso } from "services/terms.service";
import { toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";

export default function Terms() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [termo, setTermo] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (user) {
      if (user.extra.acceptTerms) {
        navigate("/inicio");
      }
      getTermosDeUso()
        .then(function (data) {
          setTermo(data[0]);
        })
        .finally(function () {
          setLoading(false);
        });
    }
  }, [user]);

  function handleAcceptTerms() {
    if (user) {
      if (user?.extra) {
        user.extra.acceptTerms = true;
      } else {
        user.extra = { acceptTerms: true };
      }
    }

    updateTermosDeUso(user).then(function (data) {
      if (data.time) {
        toast(`Termos de uso aceito com sucesso.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/inicio");
      } else {
        toast(
          `❌ Ocorreu um erro ao aceitar os termos de uso, tente novamente mais tarde.`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    });
  }

  if (isAuthenticated) {
    return (
      <>
        <Helmet>
          <title>Termos de uso - VAI PRA CIMA, BRB!</title>
        </Helmet>
        <div id="home-wrapper" className="min-h-screen">
          <div className="z-10 relative flex flex-col items-center space-y-8 md:space-y-10 md:p-10">
            <NavMenu />
            <UserInfo />
            <div className="w-full max-w-[1024px] rounded bg-white p-6 max-h-[400px] min-h-[400px] overflow-y-auto mt-10">
              <h1 className="text-4xl">TERMOS DE USO</h1>
              <div className="">
                {!loading && termo && (
                  <>
                    <div
                      className="w-full"
                      dangerouslySetInnerHTML={{ __html: termo.html }}
                    ></div>
                  </>
                )}
                {loading && (
                  <p className="text-[1.6em] text-center mt-[50px]">
                    Carregando...
                  </p>
                )}
              </div>
            </div>
            <button
              className="px-3 py-3 bg-[#00ADEE] text-white rounded mt-5"
              onClick={() => handleAcceptTerms()}
            >
              ACEITAR TERMOS
            </button>
          </div>
        </div>
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Termos de uso - VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div className="z-10 relative flex flex-col items-center space-y-8 md:space-y-10 md:p-10">
          <Link
            to="/"
            className=" px-3 py-3 bg-[#00ADEE] text-white rounded mt-5"
          >
            VOLTAR
          </Link>
          <div className="w-full max-w-[1024px] rounded bg-white p-6 max-h-[400px] overflow-y-auto">
            <h1 className="text-4xl">TERMOS DE USO</h1>
            <div className="">
              {termo && (
                <>
                  <div
                    className="w-full"
                    dangerouslySetInnerHTML={{ __html: termo.html }}
                  ></div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
