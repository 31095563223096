import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";
import { PiStarThin } from "react-icons/pi";
import { BiSolidLockAlt } from "react-icons/bi";
import LazyLoad from "react-lazy-load";

import "./styles.scss";

export function ModalSelecionarCard() {
  const { user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();

  // Função chamada quando um item é selecionado na modal
  const handleItemSelection = (selectedItemId: any, cardUnidade?: any) => {
    // Chame a função para atualizar o valor de initialSwap.rewards.item no componente pai
    // Você pode passar o ID do item selecionado como argumento
    modalData.addItemToRewards(selectedItemId, cardUnidade);
  };

  const modalID = "modal-selecionar-card";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  if (!user) return null;

  if (!modalData || modalData?.length < 1) return null;

  const cards = modalData.cards;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalSelecionarCard">
            <div className="bg-white flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>
              <h2 className="text-[1.6em]">
                Selecione o card que você quer ofertar.
              </h2>
              <div className="text-center containerSwaps">
                {cards.map((item: any, i: number) => (
                  <div className="swap" key={i}>
                    <div className="infoHeader">
                      <div className="desafio">{i + 1}</div>
                      <p>{item.card.name}</p>
                    </div>
                    <div className="front">
                      <LazyLoad height={150} offset={150}>
                        <div className="cardFront">
                          <img src={item.imgPremio} className="imgBg" alt="" />
                          <img
                            src={item.card.image.medium.url}
                            className="imgDetailCard"
                            alt=""
                          />
                        </div>
                      </LazyLoad>
                      <p>{!item.my && <BiSolidLockAlt />}</p>
                      <div className="presenteVerde">{item.my || 0}</div>
                      <div className="infoObjective detailInfo">
                        <h2 className="titleObjectives">
                          {item.my ? (
                            <span>
                              {item.cardUnidade ? (
                                <b className="">Sua Unidade Conquistou!</b>
                              ) : (
                                <b className="">Você Conquistou!</b>
                              )}
                            </span>
                          ) : (
                            <span>
                              {item.cardUnidade ? (
                                <b className="">A Unidade Pode Alcançar</b>
                              ) : (
                                <b className="">Você Pode Alcançar</b>
                              )}
                            </span>
                          )}
                        </h2>
                        {item.my ? (
                          <span>
                            {item.cardUnidade ? (
                              <p>
                                Unidade possui {item.my} card(s) desta peça.
                              </p>
                            ) : (
                              <p>Você possui {item.my} card(s) desta peça.</p>
                            )}
                          </span>
                        ) : (
                          <span>
                            {item.cardUnidade ? (
                              <p>
                                A unidade ainda não tem esse card. Supere mais
                                objetivos e tenha a chance de conseguir.
                              </p>
                            ) : (
                              <p>
                                Você ainda não tem esse card. Supere mais
                                objetivos e tenha a chance de conseguir.
                              </p>
                            )}
                          </span>
                        )}

                        {item.my ? (
                          <button
                            className="bg-[#062784] text-white p-2 w-[150px] mt-5"
                            ng-click="selectCard(obj)"
                            onClick={() =>
                              handleItemSelection(
                                item.card._id,
                                item.cardUnidade
                              )
                            }
                          >
                            OFERTAR 1 CARD
                          </button>
                        ) : (
                          <button
                            className="bg-[#888] text-white p-2 w-[150px] mt-5"
                            ng-click="selectCard(obj)"
                          >
                            NÃO POSSUI
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
